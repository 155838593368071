<template>
  <div class="">
    <div class="text-left bg-white sidebar" style="overflow-x: hidden">
      <div
        class="page-setting"
        v-if="editMode || activeSideBar == 'ImageStyle'"
      >
        <div class="d-flex justify-content-between">
          <button
            style="cursor: pointer"
            class="d-flex align-items-center page-setting-btn"
            @click="backToMainPage()"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3L10.0575 4.0575L5.8725 8.25H15V9.75H5.8725L10.0575 13.9425L9 15L3 9L9 3Z"
                fill="#8C898F"
              /></svg
            >&emsp; Page Settings
          </button>
        </div>
      </div>

      <div
        class="form-group d-flex align-items-center row w-100 m-0"
        v-if="
          activeSideBar == 'pageStyle' &&
          !editMode &&
          CurrentSelectedPage.value == 'resultPage' &&
          showComponent(['resultPage'])
        "
      >
        <div class="col-12 pt-3">
          <p class="sidebarElementTitle">Choose Page Type</p>
          <p class="sidebarElementSubTitle">
            * Customize the view of the Result page or if products are not
            found, select a Thank you page template
          </p>

          <div
            class="roundedContainer d-flex align-items-center justify-content-between cursor-pointer mb-2"
            style="padding: 12px 16px 12px 16px"
            :class="getResultPageType == 'resultPage' ? 'selected' : null"
            @click="changePageType('resultPage')"
          >
            <span
              class="sidebarElementSubTitle"
              style="font-size: 15px"
              :style="
                getResultPageType == 'resultPage' ? 'color:#4d1b7e' : null
              "
              >Result</span
            >
            <div>
              <svg
                v-if="getResultPageType == 'resultPage'"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.99996 16.6667C8.23185 16.6667 6.53616 15.9643 5.28591 14.7141C4.03567 13.4638 3.33329 11.7681 3.33329 10C3.33329 8.23191 4.03567 6.53622 5.28591 5.28598C6.53616 4.03573 8.23185 3.33335 9.99996 3.33335C11.7681 3.33335 13.4638 4.03573 14.714 5.28598C15.9642 6.53622 16.6666 8.23191 16.6666 10C16.6666 11.7681 15.9642 13.4638 14.714 14.7141C13.4638 15.9643 11.7681 16.6667 9.99996 16.6667ZM9.99996 1.66669C8.90561 1.66669 7.82198 1.88224 6.81093 2.30102C5.79988 2.71981 4.88122 3.33364 4.1074 4.10746C2.5446 5.67027 1.66663 7.78988 1.66663 10C1.66663 12.2102 2.5446 14.3298 4.1074 15.8926C4.88122 16.6664 5.79988 17.2802 6.81093 17.699C7.82198 18.1178 8.90561 18.3334 9.99996 18.3334C12.2101 18.3334 14.3297 17.4554 15.8925 15.8926C17.4553 14.3298 18.3333 12.2102 18.3333 10C18.3333 8.90567 18.1177 7.82204 17.699 6.81099C17.2802 5.79994 16.6663 4.88129 15.8925 4.10746C15.1187 3.33364 14.2 2.71981 13.189 2.30102C12.1779 1.88224 11.0943 1.66669 9.99996 1.66669ZM9.99996 5.83335C8.89489 5.83335 7.83508 6.27234 7.05368 7.05374C6.27228 7.83514 5.83329 8.89495 5.83329 10C5.83329 11.1051 6.27228 12.1649 7.05368 12.9463C7.83508 13.7277 8.89489 14.1667 9.99996 14.1667C11.105 14.1667 12.1648 13.7277 12.9462 12.9463C13.7276 12.1649 14.1666 11.1051 14.1666 10C14.1666 8.89495 13.7276 7.83514 12.9462 7.05374C12.1648 6.27234 11.105 5.83335 9.99996 5.83335Z"
                  fill="#4D1B7E"
                />
              </svg>

              <svg
                v-else
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.99996 16.6667C8.23185 16.6667 6.53616 15.9643 5.28591 14.7141C4.03567 13.4638 3.33329 11.7681 3.33329 10C3.33329 8.23191 4.03567 6.53622 5.28591 5.28598C6.53616 4.03573 8.23185 3.33335 9.99996 3.33335C11.7681 3.33335 13.4638 4.03573 14.714 5.28598C15.9642 6.53622 16.6666 8.23191 16.6666 10C16.6666 11.7681 15.9642 13.4638 14.714 14.7141C13.4638 15.9643 11.7681 16.6667 9.99996 16.6667ZM9.99996 1.66669C8.90561 1.66669 7.82198 1.88224 6.81093 2.30102C5.79988 2.71981 4.88122 3.33364 4.1074 4.10746C2.5446 5.67027 1.66663 7.78988 1.66663 10C1.66663 12.2102 2.5446 14.3298 4.1074 15.8926C4.88122 16.6664 5.79988 17.2802 6.81093 17.699C7.82198 18.1178 8.90561 18.3334 9.99996 18.3334C12.2101 18.3334 14.3297 17.4554 15.8925 15.8926C17.4553 14.3298 18.3333 12.2102 18.3333 10C18.3333 8.90567 18.1177 7.82204 17.699 6.81099C17.2802 5.79994 16.6663 4.88129 15.8925 4.10746C15.1187 3.33364 14.2 2.71981 13.189 2.30102C12.1779 1.88224 11.0943 1.66669 9.99996 1.66669Z"
                  fill="#73738D"
                />
              </svg>
            </div>
          </div>
          <div
            class="roundedContainer d-flex align-items-center justify-content-between cursor-pointer"
            style="padding: 12px 16px 12px 16px"
            :class="getResultPageType == 'noResultPage' ? 'selected' : null"
            @click="changePageType('noResultPage')"
          >
            <span
              class="sidebarElementSubTitle"
              style="font-size: 15px"
              :style="
                getResultPageType == 'noResultPage' ? 'color:#4d1b7e' : null
              "
              >Thank you page</span
            >
            <div>
              <svg
                v-if="getResultPageType == 'noResultPage'"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.99996 16.6667C8.23185 16.6667 6.53616 15.9643 5.28591 14.7141C4.03567 13.4638 3.33329 11.7681 3.33329 10C3.33329 8.23191 4.03567 6.53622 5.28591 5.28598C6.53616 4.03573 8.23185 3.33335 9.99996 3.33335C11.7681 3.33335 13.4638 4.03573 14.714 5.28598C15.9642 6.53622 16.6666 8.23191 16.6666 10C16.6666 11.7681 15.9642 13.4638 14.714 14.7141C13.4638 15.9643 11.7681 16.6667 9.99996 16.6667ZM9.99996 1.66669C8.90561 1.66669 7.82198 1.88224 6.81093 2.30102C5.79988 2.71981 4.88122 3.33364 4.1074 4.10746C2.5446 5.67027 1.66663 7.78988 1.66663 10C1.66663 12.2102 2.5446 14.3298 4.1074 15.8926C4.88122 16.6664 5.79988 17.2802 6.81093 17.699C7.82198 18.1178 8.90561 18.3334 9.99996 18.3334C12.2101 18.3334 14.3297 17.4554 15.8925 15.8926C17.4553 14.3298 18.3333 12.2102 18.3333 10C18.3333 8.90567 18.1177 7.82204 17.699 6.81099C17.2802 5.79994 16.6663 4.88129 15.8925 4.10746C15.1187 3.33364 14.2 2.71981 13.189 2.30102C12.1779 1.88224 11.0943 1.66669 9.99996 1.66669ZM9.99996 5.83335C8.89489 5.83335 7.83508 6.27234 7.05368 7.05374C6.27228 7.83514 5.83329 8.89495 5.83329 10C5.83329 11.1051 6.27228 12.1649 7.05368 12.9463C7.83508 13.7277 8.89489 14.1667 9.99996 14.1667C11.105 14.1667 12.1648 13.7277 12.9462 12.9463C13.7276 12.1649 14.1666 11.1051 14.1666 10C14.1666 8.89495 13.7276 7.83514 12.9462 7.05374C12.1648 6.27234 11.105 5.83335 9.99996 5.83335Z"
                  fill="#4D1B7E"
                />
              </svg>

              <svg
                v-else
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.99996 16.6667C8.23185 16.6667 6.53616 15.9643 5.28591 14.7141C4.03567 13.4638 3.33329 11.7681 3.33329 10C3.33329 8.23191 4.03567 6.53622 5.28591 5.28598C6.53616 4.03573 8.23185 3.33335 9.99996 3.33335C11.7681 3.33335 13.4638 4.03573 14.714 5.28598C15.9642 6.53622 16.6666 8.23191 16.6666 10C16.6666 11.7681 15.9642 13.4638 14.714 14.7141C13.4638 15.9643 11.7681 16.6667 9.99996 16.6667ZM9.99996 1.66669C8.90561 1.66669 7.82198 1.88224 6.81093 2.30102C5.79988 2.71981 4.88122 3.33364 4.1074 4.10746C2.5446 5.67027 1.66663 7.78988 1.66663 10C1.66663 12.2102 2.5446 14.3298 4.1074 15.8926C4.88122 16.6664 5.79988 17.2802 6.81093 17.699C7.82198 18.1178 8.90561 18.3334 9.99996 18.3334C12.2101 18.3334 14.3297 17.4554 15.8925 15.8926C17.4553 14.3298 18.3333 12.2102 18.3333 10C18.3333 8.90567 18.1177 7.82204 17.699 6.81099C17.2802 5.79994 16.6663 4.88129 15.8925 4.10746C15.1187 3.33364 14.2 2.71981 13.189 2.30102C12.1779 1.88224 11.0943 1.66669 9.99996 1.66669Z"
                  fill="#73738D"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <page-design-customize
        :page-design="pageDesign"
        :isDesktopView="isDesktopView"
        v-show="activeSideBar == 'pageStyle' && !editMode"
      ></page-design-customize>

      <image-design-customize
        :page-design="pageDesign"
        :order="CurrentSelectedPage.order"
        :isDesktopView="isDesktopView"
        v-if="activeSideBar == 'ImageStyle'"
        @backtomainpage="backToMainPage()"
        @removeBGImage="removeBgImage()"
      ></image-design-customize>

      <div class="row action-buttons m-0" v-show="!editMode">
        <div
          class="col-12 col-lg-6 mb-4"
          v-show="!IsImageAdded && showComponent(['all'])"
        >
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center"
            @click="addImage()"
            v-b-tooltip.top
            title="Customize the background of your quiz for a personalized look."
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_154_2545)">
                <path
                  d="M17.5 2.5C17.721 2.5 17.933 2.5878 18.0893 2.74408C18.2456 2.90036 18.3334 3.11232 18.3334 3.33333V16.6667C18.3334 16.8877 18.2456 17.0996 18.0893 17.2559C17.933 17.4122 17.721 17.5 17.5 17.5H2.50002C2.27901 17.5 2.06704 17.4122 1.91076 17.2559C1.75448 17.0996 1.66669 16.8877 1.66669 16.6667V3.33333C1.66669 3.11232 1.75448 2.90036 1.91076 2.74408C2.06704 2.5878 2.27901 2.5 2.50002 2.5H17.5ZM16.6667 4.16667H3.33335V15.8333H16.6667V4.16667ZM9.16669 5.83333V14.1667H5.00002V5.83333H9.16669Z"
                  fill="#73738D"
                />
              </g>
              <defs>
                <clipPath id="clip0_154_2545">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <p
              class="m-0 text-center"
              v-if="
                CurrentSelectedPage.value == 'resultPage' &&
                getResultPageType == 'resultPage'
              "
            >
              Background
            </p>
            <p class="m-0 text-center" v-else>Side Image</p>
          </div>
        </div>
        <div
          class="col-12 col-lg-6 mb-4"
          v-show="IsImageAdded && showComponent(['all'])"
        >
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center"
            @click="editImage()"
            v-b-tooltip.top
            title="Add side or background images to enhance visual appeal and complement your content."
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_154_2545)">
                <path
                  d="M17.5 2.5C17.721 2.5 17.933 2.5878 18.0893 2.74408C18.2456 2.90036 18.3334 3.11232 18.3334 3.33333V16.6667C18.3334 16.8877 18.2456 17.0996 18.0893 17.2559C17.933 17.4122 17.721 17.5 17.5 17.5H2.50002C2.27901 17.5 2.06704 17.4122 1.91076 17.2559C1.75448 17.0996 1.66669 16.8877 1.66669 16.6667V3.33333C1.66669 3.11232 1.75448 2.90036 1.91076 2.74408C2.06704 2.5878 2.27901 2.5 2.50002 2.5H17.5ZM16.6667 4.16667H3.33335V15.8333H16.6667V4.16667ZM9.16669 5.83333V14.1667H5.00002V5.83333H9.16669Z"
                  fill="#73738D"
                />
              </g>
              <defs>
                <clipPath id="clip0_154_2545">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <p class="m-0 text-center">Side Image</p>
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-4" v-if="showComponent(['resultPage'])">
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center"
            :class="false ? 'div-disable' : 'cursorPointer'"
            @click="handleChangeCustomize(getProductRankOptionArrayIndex)"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8333 5.00004H14.1667C14.1667 2.66671 12.3333 0.833374 10 0.833374C7.66667 0.833374 5.83333 2.66671 5.83333 5.00004H4.16667C3.25 5.00004 2.5 5.75004 2.5 6.66671V16.6667C2.5 17.5834 3.25 18.3334 4.16667 18.3334H15.8333C16.75 18.3334 17.5 17.5834 17.5 16.6667V6.66671C17.5 5.75004 16.75 5.00004 15.8333 5.00004ZM10 2.50004C11.4167 2.50004 12.5 3.58337 12.5 5.00004H7.5C7.5 3.58337 8.58333 2.50004 10 2.50004ZM15.8333 16.6667H4.16667V6.66671H15.8333V16.6667ZM10 10C8.58333 10 7.5 8.91671 7.5 7.50004H5.83333C5.83333 9.83337 7.66667 11.6667 10 11.6667C12.3333 11.6667 14.1667 9.83337 14.1667 7.50004H12.5C12.5 8.91671 11.4167 10 10 10Z"
                fill="#73738D"
              />
            </svg>

            <p class="m-0 text-center">Edit Products</p>
          </div>
        </div>

        <div class="col-12 col-lg-6 mb-4">
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center cursorPointer"
            @click="addNewText()"
            :draggable="true"
            v-b-tooltip.top
            title="Add descriptive text to guide users or provide quiz information."
            @dragstart="startDrag($event, 'AddText')"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 2.5H13.3333V5H9.16667V15H6.66667V5H2.5V2.5ZM10 5.83333H11.6667V7.5H10V5.83333ZM12.5 5.83333H14.1667V7.5H12.5V5.83333ZM15 5.83333H16.6667V7.5H15V5.83333ZM10 8.33333H11.6667V10H10V8.33333ZM10 10.8333H11.6667V12.5H10V10.8333ZM10 13.3333H11.6667V15H10V13.3333ZM10 15.8333H11.6667V17.5H10V15.8333Z"
                fill="#73738D"
              />
            </svg>

            <p class="m-0 text-center">Add text</p>
          </div>
        </div>
        <div
          class="col-12 col-lg-6 mb-4"
          v-if="getResultPageType != 'noResultPage'"
        >
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center cursorPointer"
            @click="addHtmlElement()"
            :draggable="true"
            v-b-tooltip.top
            title="Embed custom HTML to enhance design flexibility and content richness."
            @dragstart="startDrag($event, 'AddHtml')"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.6585 2.84155L5.8335 4.01655L3.1835 6.66655L5.8335 9.31655L4.6585 10.4999L0.833496 6.66655L4.6585 2.84155ZM9.5085 2.84155L13.3335 6.66655L9.5085 10.4999L8.3335 9.31655L10.9835 6.66655L8.3335 4.01655L9.5085 2.84155ZM18.3335 4.99989V14.9999C18.3335 15.9249 17.5918 16.6666 16.6668 16.6666H3.3335C2.41683 16.6666 1.66683 15.9249 1.66683 14.9999V11.6666H3.3335V14.9999H16.6668V4.99989H14.1918V3.33322H16.6668C17.5918 3.33322 18.3335 4.07489 18.3335 4.99989Z"
                fill="#73738D"
              />
            </svg>

            <p class="m-0 text-center">Add Html</p>
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center cursorPointer"
            @click="addInlineImageElement()"
            :draggable="true"
            v-b-tooltip.top
            title="Add Image as an inline element."
            @dragstart="startDrag($event, 'AddInlineImage')"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8333 15.8333H4.16667V4.16667H15.8333M15.8333 2.5H4.16667C3.72464 2.5 3.30072 2.67559 2.98816 2.98816C2.67559 3.30072 2.5 3.72464 2.5 4.16667V15.8333C2.5 16.2754 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H15.8333C16.2754 17.5 16.6993 17.3244 17.0118 17.0118C17.3244 16.6993 17.5 16.2754 17.5 15.8333V4.16667C17.5 3.72464 17.3244 3.30072 17.0118 2.98816C16.6993 2.67559 16.2754 2.5 15.8333 2.5ZM11.6333 10.2417L9.34167 13.1917L7.70833 11.225L5.41667 14.1667H14.5833L11.6333 10.2417Z"
                fill="#73738D"
              />
            </svg>

            <p class="m-0 text-center">Inline Image</p>
          </div>
        </div>
        <div
          class="col-12 col-lg-6 mb-4"
          v-if="showComponent(['singleAnswer', 'multipleAnswer'])"
        >
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center cursorPointer"
            @click="handleChangeCustomize(getOptionIndex)"
          >
            <svg
              width="24"
              height="19"
              viewBox="0 0 24 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.916626 0.666626V4.16663H6.74996V18.1666H10.25V4.16663H16.0833V0.666626H0.916626ZM23.0833 6.49996H12.5833V9.99996H16.0833V18.1666H19.5833V9.99996H23.0833V6.49996Z"
                fill="#6D6B6D"
              />
            </svg>

            <p class="m-0 text-center">Edit Options</p>
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center"
            :class="AddButtonClass"
            @click="addButton()"
            :draggable="true"
            v-b-tooltip.top
            title="Place extra buttons to link to external resources or sites."
            @dragstart="startDrag($event, 'AddButton')"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8333 5H18.3333V6.66667H15.8333V9.16667H14.1667V6.66667H11.6667V5H14.1667V2.5H15.8333V5ZM14.1667 14.1667V11.6667H15.8333V15.8333H2.5V5H9.16667V6.66667H4.16667V14.1667H14.1667Z"
                fill="#73738D"
              />
            </svg>

            <p class="m-0 text-center">Add buttton</p>
          </div>
        </div>

        <div
          class="col-12 col-lg-6 mb-4"
          v-if="showComponent(['getStartedPage'])"
        >
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center"
            :class="[maxAddTimer() > 0 ? ' div-disable' : 'cursorPointer']"
            @click="addTimer()"
            :draggable="true"
            v-b-tooltip.top
            title="Set a timer to limit response time and add urgency to quizzes."
            @dragstart="startDrag($event, 'AddTimer')"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.3335 2.5H11.6668M10.0002 7.5V10.8333L11.6668 12.5M16.6668 10.8333C16.6668 14.5152 13.6821 17.5 10.0002 17.5C6.31826 17.5 3.3335 14.5152 3.3335 10.8333C3.3335 7.15143 6.31826 4.16667 10.0002 4.16667C13.6821 4.16667 16.6668 7.15143 16.6668 10.8333Z"
                stroke="#73738D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="m-0 text-center">Add Timer</p>
          </div>
        </div>

        <!-- Lead Page Buttons start -->
        <div class="col-12 col-lg-6 mb-4" v-if="showComponent('leadPage')">
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center cursorPointer"
            @click="openInputFieldsModal"
            v-b-tooltip.top
            title="Collect user data such as name and email through input fields."
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 14.1667C5 12.5 8.33333 11.5833 10 11.5833C11.6667 11.5833 15 12.5 15 14.1667V15H5M12.5 7.5C12.5 8.16304 12.2366 8.79893 11.7678 9.26777C11.2989 9.73661 10.663 10 10 10C9.33696 10 8.70107 9.73661 8.23223 9.26777C7.76339 8.79893 7.5 8.16304 7.5 7.5C7.5 6.83696 7.76339 6.20107 8.23223 5.73223C8.70107 5.26339 9.33696 5 10 5C10.663 5 11.2989 5.26339 11.7678 5.73223C12.2366 6.20107 12.5 6.83696 12.5 7.5ZM2.5 4.16667V15.8333C2.5 16.2754 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H15.8333C16.2754 17.5 16.6993 17.3244 17.0118 17.0118C17.3244 16.6993 17.5 16.2754 17.5 15.8333V4.16667C17.5 3.72464 17.3244 3.30072 17.0118 2.98816C16.6993 2.67559 16.2754 2.5 15.8333 2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667Z"
                fill="#73738D"
              />
            </svg>

            <p class="m-0 text-center">Lead Input</p>
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-4" v-if="showComponent(['leadPage'])">
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center"
            :class="maxCheckBoxLimit > 3 ? 'div-disable' : 'cursorPointer'"
            @click="addCheckbox"
            :draggable="true"
            v-b-tooltip.top
            title="Include a checkbox for users to agree to terms and conditions before proceeding."
            @dragstart="startDrag($event, 'AddCheckbox')"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8333 2.5H4.16667C3.72464 2.5 3.30072 2.67559 2.98816 2.98816C2.67559 3.30072 2.5 3.72464 2.5 4.16667V15.8333C2.5 16.2754 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H15.8333C16.2754 17.5 16.6993 17.3244 17.0118 17.0118C17.3244 16.6993 17.5 16.2754 17.5 15.8333V4.16667C17.5 3.72464 17.3244 3.30072 17.0118 2.98816C16.6993 2.67559 16.2754 2.5 15.8333 2.5ZM15.8333 4.16667V15.8333H4.16667V4.16667H15.8333ZM8.33333 14.1667L5 10.8333L6.175 9.65L8.33333 11.8083L13.825 6.31667L15 7.5"
                fill="#73738D"
              />
            </svg>

            <p class="m-0 text-center">Check box</p>
          </div>
        </div>

        <div class="col-12 col-lg-6 mb-4" v-if="showComponent('leadPage')">
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center cursorPointer"
            @click="$refs.addNewAddressCompRef.open()"
            v-b-tooltip.top
            title="Gather location information with structured address fields."
            :class="
              AllAddressBlocks && AllAddressBlocks.length == 6
                ? 'div-disable'
                : 'cursorPointer'
            "
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0002 11.6667C11.3809 11.6667 12.5002 10.5474 12.5002 9.16667C12.5002 7.78595 11.3809 6.66667 10.0002 6.66667C8.61945 6.66667 7.50016 7.78595 7.50016 9.16667C7.50016 10.5474 8.61945 11.6667 10.0002 11.6667Z"
                stroke="#73738D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.0002 17.5C13.6821 15.8333 16.6668 12.8486 16.6668 9.16667C16.6668 5.48477 13.6821 2.5 10.0002 2.5C6.31826 2.5 3.3335 5.48477 3.3335 9.16667C3.3335 12.8486 6.31826 15.8333 10.0002 17.5Z"
                stroke="#73738D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p class="m-0 text-center">Address</p>
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-4" v-if="showComponent(['leadPage'])">
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center"
            :class="maxFileUpload > 0 ? 'div-disable' : 'cursorPointer'"
            @click="addFileUpload"
            :draggable="true"
            v-b-tooltip.top
            title="Allow users to upload files as part of their quiz responses."
            @dragstart="startDrag($event, 'AddFileUpload')"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.6668 1.66675L16.6668 6.66675V16.6667C16.6668 17.1088 16.4912 17.5327 16.1787 17.8453C15.8661 18.1578 15.4422 18.3334 15.0002 18.3334H5.00016C4.55814 18.3334 4.13421 18.1578 3.82165 17.8453C3.50909 17.5327 3.3335 17.1088 3.3335 16.6667V3.33341C3.3335 2.89139 3.50909 2.46746 3.82165 2.1549C4.13421 1.84234 4.55814 1.66675 5.00016 1.66675H11.6668ZM15.0002 16.6667V7.50008H10.8335V3.33341H5.00016V16.6667H15.0002ZM10.0002 10.0001L13.3335 13.3334H11.2502V15.8334H8.75016V13.3334H6.66683L10.0002 10.0001Z"
                fill="#73738D"
              />
            </svg>

            <p class="m-0 text-center">File Upload</p>
          </div>
        </div>
        <!-- Lead Page Buttons end -->

        <!-- Results Page Buttons start -->
        <div class="col-12 col-lg-6 mb-4" v-if="showComponent(['resultPage'])">
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center"
            :class="maxSocialLinks != 0 ? 'div-disable' : 'cursorPointer'"
            @click="addSocialMedia"
            v-b-tooltip.top
            title="Include links to social media to enhance connectivity and sharing options."
            :draggable="true"
            @dragstart="startDrag($event, 'AddSocialMedia')"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.66683 8.33329V16.6666M6.66683 8.33329L3.3335 8.33328V16.6666L6.66683 16.6666M6.66683 8.33329L10.9965 3.28202C11.4073 2.80271 12.0537 2.59666 12.6661 2.74977L12.7058 2.75968C13.8239 3.03921 14.3276 4.3421 13.6883 5.30108L11.6668 8.33328H15.4672C16.5189 8.33328 17.3077 9.29549 17.1015 10.3268L16.1015 15.3268C15.9456 16.1058 15.2616 16.6666 14.4672 16.6666L6.66683 16.6666"
                stroke="#73738D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p class="m-0 text-center">Social Links</p>
          </div>
        </div>
        <!-- Results Page Buttons start -->
        <div class="col-12 col-lg-6 mb-4" v-if="showComponent(['resultPage'])">
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center"
            :class="maxShareLinks != 0 ? 'div-disable' : 'cursorPointer'"
            @click="addShareLinks"
            :draggable="true"
            v-b-tooltip.top
            title="Enable users to share their quiz results via a custom link, promoting your quiz on social media."
            @dragstart="startDrag($event, 'AddShareLinks')"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.6665 10.8333C5.1165 10.8333 3.82484 11.9 3.44984 13.3333H1.6665V15H3.44984C3.82484 16.4333 5.1165 17.5 6.6665 17.5C8.2165 17.5 9.50817 16.4333 9.88317 15H18.3332V13.3333H9.88317C9.50817 11.9 8.2165 10.8333 6.6665 10.8333ZM6.6665 15.8333C5.74984 15.8333 4.99984 15.0833 4.99984 14.1667C4.99984 13.25 5.74984 12.5 6.6665 12.5C7.58317 12.5 8.33317 13.25 8.33317 14.1667C8.33317 15.0833 7.58317 15.8333 6.6665 15.8333ZM16.5498 5C16.1748 3.56667 14.8832 2.5 13.3332 2.5C11.7832 2.5 10.4915 3.56667 10.1165 5H1.6665V6.66667H10.1165C10.4915 8.1 11.7832 9.16667 13.3332 9.16667C14.8832 9.16667 16.1748 8.1 16.5498 6.66667H18.3332V5H16.5498ZM13.3332 7.5C12.4165 7.5 11.6665 6.75 11.6665 5.83333C11.6665 4.91667 12.4165 4.16667 13.3332 4.16667C14.2498 4.16667 14.9998 4.91667 14.9998 5.83333C14.9998 6.75 14.2498 7.5 13.3332 7.5Z"
                fill="#73738D"
              />
            </svg>

            <p class="m-0 text-center">Share Result</p>
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-4" v-if="showComponent(['all'])">
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center cursorPointer"
            @click="addScript"
            v-b-tooltip.top
            title="Insert custom JavaScript for advanced functionality and interactions."
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.66667 2.5C6.22464 2.5 5.80072 2.67559 5.48816 2.98816C5.17559 3.30072 5 3.72464 5 4.16667V7.5C5 7.94203 4.82441 8.36595 4.51184 8.67851C4.19928 8.99107 3.77536 9.16667 3.33333 9.16667H2.5V10.8333H3.33333C3.77536 10.8333 4.19928 11.0089 4.51184 11.3215C4.82441 11.634 5 12.058 5 12.5V15.8333C5 16.2754 5.17559 16.6993 5.48816 17.0118C5.80072 17.3244 6.22464 17.5 6.66667 17.5H8.33333V15.8333H6.66667V11.6667C6.66667 11.2246 6.49107 10.8007 6.17851 10.4882C5.86595 10.1756 5.44203 10 5 10C5.44203 10 5.86595 9.8244 6.17851 9.51184C6.49107 9.19928 6.66667 8.77536 6.66667 8.33333V4.16667H8.33333V2.5M13.3333 2.5C13.7754 2.5 14.1993 2.67559 14.5118 2.98816C14.8244 3.30072 15 3.72464 15 4.16667V7.5C15 7.94203 15.1756 8.36595 15.4882 8.67851C15.8007 8.99107 16.2246 9.16667 16.6667 9.16667H17.5V10.8333H16.6667C16.2246 10.8333 15.8007 11.0089 15.4882 11.3215C15.1756 11.634 15 12.058 15 12.5V15.8333C15 16.2754 14.8244 16.6993 14.5118 17.0118C14.1993 17.3244 13.7754 17.5 13.3333 17.5H11.6667V15.8333H13.3333V11.6667C13.3333 11.2246 13.5089 10.8007 13.8215 10.4882C14.134 10.1756 14.558 10 15 10C14.558 10 14.134 9.8244 13.8215 9.51184C13.5089 9.19928 13.3333 8.77536 13.3333 8.33333V4.16667H11.6667V2.5H13.3333Z"
                fill="#73738D"
              />
            </svg>

            <p class="m-0 text-center">Add Script</p>
          </div>
        </div>

        <div
          class="col-12 col-lg-6 mb-4"
          v-if="
            showComponent(['resultPage']) && getResultPageType == `resultPage`
          "
        >
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center"
            :class="maxCouponComp != 0 ? 'div-disable' : 'cursorPointer'"
            @click="addCouponComp"
            :draggable="true"
            v-b-tooltip.top
            title="Offer coupons or discounts as incentives for completing your quiz."
            @dragstart="startDrag($event, 'AddCouponComp')"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.66667 13.3333L13.3333 6.66667M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10ZM14.1667 12.5C14.1667 13.4205 13.4205 14.1667 12.5 14.1667C11.5795 14.1667 10.8333 13.4205 10.8333 12.5C10.8333 11.5795 11.5795 10.8333 12.5 10.8333C13.4205 10.8333 14.1667 11.5795 14.1667 12.5ZM9.16667 7.5C9.16667 8.42047 8.42047 9.16667 7.5 9.16667C6.57953 9.16667 5.83333 8.42047 5.83333 7.5C5.83333 6.57953 6.57953 5.83333 7.5 5.83333C8.42047 5.83333 9.16667 6.57953 9.16667 7.5Z"
                stroke="#73738D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p class="m-0 text-center">Coupon</p>
          </div>
        </div>

        <!-- Results Page Buttons end -->
        <div
          class="col-12 col-lg-6 mb-4"
          v-if="
            showComponent([
              'getStarted',
              'singleAnswer',
              'multipleAnswer',
              'leadPage',
              'stripePage',
            ])
          "
        >
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center cursorPointer"
            @click="addTooltip()"
            :draggable="true"
            v-b-tooltip.top
            title="Provide helpful hints or additional information when users hover over elements."
            @dragstart="startDrag($event, 'AddTooltip')"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.75 6.75V8.25H11.25V6.75H9.75ZM12 14.25V12.75H11.25V9.75H9V11.25H9.75V12.75H9V14.25H12ZM18 10.5C18 14.625 14.625 18 10.5 18C6.375 18 3 14.625 3 10.5C3 6.375 6.375 3 10.5 3C14.625 3 18 6.375 18 10.5ZM16.5 10.5C16.5 7.185 13.815 4.5 10.5 4.5C7.185 4.5 4.5 7.185 4.5 10.5C4.5 13.815 7.185 16.5 10.5 16.5C13.815 16.5 16.5 13.815 16.5 10.5Z"
                fill="#73738D"
              />
            </svg>

            <p class="m-0 text-center">Add Tooltip</p>
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-4" v-if="showComponent(['leadPage'])">
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center cursorPointer"
            @click="addSkipBtn()"
            :class="[maxAddSkip() > 0 ? ' div-disable' : 'cursorPointer']"
            v-b-tooltip.top
            title="Add a skip button to bypass a lead page without filling in data."
            :draggable="true"
            @dragstart="startDrag($event, 'AddSkipBtn')"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.3335 4.16675V15.8334L9.16683 10.0001M15.0002 4.16675V15.8334H16.6668V4.16675M9.16683 4.16675V15.8334L15.0002 10.0001"
                fill="#73738D"
              />
            </svg>

            <p class="m-0 text-center">Skip Lead</p>
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-4" v-if="showComponent(['leadPage'])">
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center cursorPointer"
            @click="addOTP()"
            :class="[maxAuth() > 0 ? ' div-disable' : 'cursorPointer']"
            v-b-tooltip.top
            title="Generate a one-time password for secure user authentication."
            :draggable="true"
            @dragstart="startDrag($event, 'AddOTP')"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 9.99992H15.8333C15.3917 13.4249 13.1 16.4833 10 17.4333V9.99992H4.16667V5.24992L10 2.65825M10 0.833252L2.5 4.16659V9.16659C2.5 13.7916 5.7 18.1083 10 19.1666C14.3 18.1083 17.5 13.7916 17.5 9.16659V4.16659L10 0.833252Z"
                fill="#73738D"
              />
            </svg>

            <p class="m-0 text-center">OTP</p>
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-4" v-if="showComponent('getStarted')">
          <div
            class="btn flex-column pageElement d-flex justify-content-center align-items-center"
            :class="[maxAddTimer() > 0 ? ' div-disable' : 'cursorPointer']"
            @click="addTimer()"
            v-b-tooltip.top
            title="Set a timer to limit response time and add urgency to quizzes."
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.3335 2.5H11.6668M10.0002 7.5V10.8333L11.6668 12.5M16.6668 10.8333C16.6668 14.5152 13.6821 17.5 10.0002 17.5C6.31826 17.5 3.3335 14.5152 3.3335 10.8333C3.3335 7.15143 6.31826 4.16667 10.0002 4.16667C13.6821 4.16667 16.6668 7.15143 16.6668 10.8333Z"
                stroke="#73738D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="my-1 text-center">Add Timer</p>
          </div>
        </div>
      </div>

      <div
        class="form-group row mb-2 ml-1"
        v-if="
          activeSideBar == 'pageStyle' &&
          !editMode &&
          showComponent(['singleAnswer', 'multipleAnswer'])
        "
      >
        <div class="col-12">
          <p class="pageSettingsTitle">Settings</p>
        </div>

        <div class="col-12 d-flex justify-content-between align-items-center">
          <span
            class="pageSettingHeader"
            v-b-tooltip.top
            title="Allow participants to select more than one answer for a question."
            >Multiple Answer
          </span>
          <div class="show-logo custom-control custom-switch cursor-pointer">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`MultipleOptionSwitch-${Date.now()}`"
              v-model="getQuestionPageType"
            />

            <label
              class="custom-control-label"
              :for="`MultipleOptionSwitch-${Date.now()}`"
            ></label>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between align-items-center">
          <span
            class="pageSettingHeader"
            v-b-tooltip.top
            title="Prioritize certain questions to filter out products based on critical criteria like allergies or gender, ensuring relevance."
            >Master question</span
          >
          <div class="show-logo custom-control custom-switch cursor-pointer">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`MasterQuestionSwitch-${Date.now()}`"
              v-model="IsMasterQuestion"
            />

            <label
              class="custom-control-label"
              :for="`MasterQuestionSwitch-${Date.now()}`"
            ></label>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between align-items-center">
          <span
            class="pageSettingHeader"
            v-b-tooltip.top
            title="Randomize answer choices to reduce response bias."
            >Shuffle Options</span
          >
          <div class="show-logo custom-control custom-switch cursor-pointer">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`ShuffleOptionsSwitch-${Date.now()}`"
              v-model="shuffleOptions"
            />

            <label
              class="custom-control-label"
              :for="`ShuffleOptionsSwitch-${Date.now()}`"
            ></label>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-between align-items-center">
          <span
            class="pageSettingHeader"
            v-b-tooltip.top
            title="Automatically skip over questions based on previous answers."
            >Skip Question</span
          >
          <div class="show-logo custom-control custom-switch cursor-pointer">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`SkipQuestionSwitch-${Date.now()}`"
              v-model="skip_status"
            />

            <label
              class="custom-control-label"
              :for="`SkipQuestionSwitch-${Date.now()}`"
            ></label>
          </div>
        </div>
        <div
          class="col-12 d-flex justify-content-between align-items-center"
          v-if="QuestionPageType == 'multipleAnswer'"
        >
          <span class="pageSettingHeader">Limit Selection:</span>
          <div class="show-logo custom-control custom-switch cursor-pointer">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`limitSelection-${Date.now()}`"
              v-model="
                pageDesign.blocksArray[getOptionIndex].limitSelection
                  .limitSelectionValue
              "
            />

            <label
              class="custom-control-label"
              :for="`limitSelection-${Date.now()}`"
            ></label>
          </div>
        </div>

        <div
          v-if="
            QuestionPageType == 'multipleAnswer' &&
            pageDesign.blocksArray[getOptionIndex].limitSelection
              .limitSelectionValue
          "
          class="col-12"
        >
          <div class="d-flex mt-1 justify-content-between align-items-center">
            <span class="pageSettingHeader">Limit Type</span>
            <select
              class="form-select w-50 form-control-sm pl-2 ml-2 input-border-style"
              aria-label="Default select example"
              v-model="
                pageDesign.blocksArray[getOptionIndex].limitSelection
                  .limitSelectionType
              "
            >
              <option value="exact">Exact</option>
              <option value="range">Range</option>
            </select>
          </div>
          <div class="d-flex mt-1 align-items-center justify-content-between">
            <span class="pageSettingHeader">Limit Value</span>

            <div
              v-if="
                pageDesign.blocksArray[getOptionIndex].limitSelection
                  .limitSelectionType == 'exact'
              "
              class=""
            >
              <div class="settingsInput flex-grow-1">
                <input
                  type="number"
                  min="1"
                  :max="pageDesign.blocksArray[getOptionIndex].options.length"
                  v-model="
                    pageDesign.blocksArray[getOptionIndex].limitSelection
                      .exactSelection
                  "
                  class="w-100"
                />
              </div>
            </div>
            <div
              v-if="
                pageDesign.blocksArray[getOptionIndex].limitSelection
                  .limitSelectionType == 'range'
              "
              class="d-flex align-items-center"
            >
              <div class="settingsInput flex-grow-1">
                <input
                  type="number"
                  min="1"
                  :max="pageDesign.blocksArray[getOptionIndex].options.length"
                  v-model="
                    pageDesign.blocksArray[getOptionIndex].limitSelection
                      .minRangeSelection
                  "
                  class="w-100"
                />
              </div>
              <div class="settingsInput flex-grow-1">
                <input
                  type="number"
                  min="1"
                  :max="pageDesign.blocksArray[getOptionIndex].options.length"
                  v-model="
                    pageDesign.blocksArray[getOptionIndex].limitSelection
                      .maxRangeSelection
                  "
                  class="w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <!-- <side-image :image-block="pageDesign.imageBlock"></side-image> -->

        <div
          v-for="(block, index) in getPageDesignBlocks"
          :key="'customize' + index"
        >
          <text-block
            v-if="block.customizeStatus && block.type == 'text'"
            :block="block"
            :isDesktopView="isDesktopView"
            :index="index"
            :isResultPage="
              CurrentSelectedPage.value == 'resultPage' ? true : false
            "
          ></text-block>
          <HtmlBlock
            v-if="block.customizeStatus && block.type == 'html'"
            :block="block"
            :isDesktopView="isDesktopView"
            :index="index"
          ></HtmlBlock>
          <button-block
            v-if="block.customizeStatus && block.type == 'button'"
            :block="block"
            :isBauerfeindClient="checkBauerfeind"
            :isBearWithBenefitsClient="isBearWithBenefitsClient"
            :isDesktopView="isDesktopView"
            :index="index"
          ></button-block>
          <payment-button-block
            v-if="block.type == 'paymentButton' && block.customizeStatus"
            :block="block"
            :selectedPayment="
              CurrentSelectedPage.pageDesign.blocksArray[getPriceCompIndex]
                .selectedPaymentMethod
            "
            :isDesktopView="isDesktopView"
            :index="index"
          ></payment-button-block>
          <button-block
            v-if="block.type == 'skipButton' && block.customizeStatus"
            :block="block"
            :isDesktopView="isDesktopView"
            :index="index"
          ></button-block>
          <back-next-button
            v-if="block.type == 'button' && staticButtonStyles.customizeStatus"
            :block="block"
            :index="index"
            :isDesktopView="isDesktopView"
            :pageType="getSelectedPage.value"
          ></back-next-button>
          <Timer-block
            v-if="block.customizeStatus && block.type == 'timer'"
            :block="block"
            :isDesktopView="isDesktopView"
            :index="index"
          ></Timer-block>
          <tool-tip-edit-block
            v-if="block.customizeStatus && block.type == 'tooltip'"
            :block="block"
            :isDesktopView="isDesktopView"
            :index="index"
          ></tool-tip-edit-block>

          <QuestionOptionsBlock
            v-if="block.customizeStatus && block.type == 'option'"
            :block="block"
            :isDesktopView="isDesktopView"
            :index="index"
            :QuestionPageType="QuestionPageType"
          />

          <!-- Lead Page Blocks start -->

          <LeadCheckboxBlock
            v-if="block.customizeStatus && block.type == 'checkbox'"
            :block="block"
            :isDesktopView="isDesktopView"
            :index="index"
            :QuestionPageType="QuestionPageType"
          />

          <file-upload-block
            v-if="block.customizeStatus && block.type == 'fileUpload'"
            :block="block"
            :isDesktopView="isDesktopView"
            :index="index"
          ></file-upload-block>

          <div v-if="block.customizeStatus && block.type == 'freeTextArea'">
            <FreeTextEdit :block="block" :isDesktopView="isDesktopView" />
          </div>
          <div v-if="block.customizeStatus && block.type == 'Auth'">
            <AuthLeadBlock :block="block" :isDesktopView="isDesktopView" />
          </div>
          <div v-if="block.customizeStatus && block.type == 'address'">
            <AddressBlock
              :block="block"
              :isDesktopView="isDesktopView"
              :addedBlocks="AllAddressBlocks"
            />
          </div>
          <div v-if="block.customizeStatus && block.type == 'freeText'">
            <LeadFreeTextBlock
              :block="block"
              :isDesktopView="isDesktopView"
              :getAllUsedLeadInputs="getAllUsedLeadInputs"
              @toggleEnableOTP="toggleEnableOTP($event)"
            />
          </div>
          <div v-if="block.customizeStatus && block.type == 'customInput'">
            <CustomFieldBlock :block="block" :isDesktopView="isDesktopView" />
          </div>

          <!-- Lead Page Blocks End -->

          <!-- Stripe Page starts-->
          <div v-if="block.customizeStatus && block.type == 'price'">
            <StripePrice
              :block="block"
              :pageId="CurrentSelectedPage.id"
              :isDesktopView="isDesktopView"
              @updateSelectedCurrency="changeSelectedCurrency($event)"
              @updateRecurringPayment="ChangeRecurringPayment($event)"
            />
          </div>
          <!-- Stripe Page Ends-->

          <!-- Result Page starts -->
          <social-links-edit
            v-if="block.customizeStatus && block.type == 'socialLinks'"
            :block="block"
            :isDesktopView="isDesktopView"
            :index="index"
          ></social-links-edit>
          <share-links-edit
            v-if="block.customizeStatus && block.type == 'shareLink'"
            :block="block"
            :isDesktopView="isDesktopView"
            :index="index"
          ></share-links-edit>
          <coupon-edit
            v-if="block.customizeStatus && block.type == 'coupon'"
            :block="block"
            :isDesktopView="isDesktopView"
            :index="index"
          ></coupon-edit>
          <product-block
            v-if="block.customizeStatus && block.type == 'product'"
            :block="block"
            :index="index"
            @AddSingleProductRankOption="AddProductRankOption()"
            @deleteSingleProductRankOption="DeleteProductRankOption($event)"
            :isDesktop="isDesktopView"
            :key="UpdateCount"
          ></product-block>
          <!-- Result Page end -->
          <!-- Slider Page Start -->

          <slider-input-block
            v-if="block.customizeStatus && block.type == 'sliderRangeInput'"
            :block="block"
            :index="index"
            :isDesktopView="isDesktopView"
          ></slider-input-block>
          <image-comp-block
            v-if="block.customizeStatus && block.type == 'imageComp'"
            :block="block"
            :index="index"
            :isDesktopView="isDesktopView"
          ></image-comp-block>
          <!-- Slider Page End -->
        </div>
      </div>
    </div>
    <ScriptModal
      ref="addScriptModal"
      :scriptCode="pageDesign.pageStyles.jsCode"
      @saveScript="saveScript"
    ></ScriptModal>

    <!-- Lead Inputs Modal Start -->
    <modal
      ref="addNewInputCompRef"
      id="addNewInputCompRef"
      size="lg"
      title="<span class='px-4'>Add new Input</span>"
    >
      <div v-if="customInputView == 'allInputsview'">
        <div v-if="customInputFieldsLoading" class="px-4 py-2">
          <div class="row m-0 w-100">
            <div
              class="col-4 col-xl-4 col-lg-6 col-xs-12 col-md-6 col-sm-12 mb-4"
              v-for="n in 6"
              :key="n"
            >
              <div
                class="bg-preload"
                style="width: 100%; height: 130px; border-radius: 4px"
              >
                <div class="into-preload"></div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="px-4 py-2">
          <div class="w-100 row m-0">
            <div class="col-12 pb-3">
              <p
                class="font-weight-bold pb-2"
                style="border-bottom: 1px solid #b3afb6"
              >
                Base Input Fields
              </p>
            </div>
            <div
              class="col-4 col-xl-4 col-lg-6 col-xs-12 col-md-6 col-sm-12 mb-4"
            >
              <div
                class="inputBxShadow d-flex flex-column rounded p-3 justify-content-center align-items-center cursorPointer"
                :class="
                  maxLeadInputsCheck('full_name')
                    ? 'div-disable'
                    : 'cursorPointer'
                "
                v-b-tooltip.hover
                :title="`Ask Full Name as text `"
                @click="addLeadInputData(`full_name`)"
              >
                <b-icon icon="fonts"></b-icon>

                <p class="my-1">Full Name</p>
              </div>
            </div>
            <div
              class="col-4 col-xl-4 col-lg-6 col-xs-12 col-md-6 col-sm-12 mb-4"
            >
              <div
                class="inputBxShadow d-flex flex-column rounded p-3 justify-content-center align-items-center cursorPointer"
                :class="
                  maxLeadInputsCheck('number') ? 'div-disable' : 'cursorPointer'
                "
                v-b-tooltip.hover
                :title="`Ask Phone Numbers based on user's country`"
                @click="addLeadInputData(`number`)"
              >
                <b-icon icon="telephone-plus-fill"></b-icon>

                <p class="my-1">Phone Number</p>
              </div>
            </div>
            <div
              class="col-4 col-xl-4 col-lg-6 col-xs-12 col-md-6 col-sm-12 mb-4"
            >
              <div
                class="inputBxShadow p-3 d-flex flex-column justify-content-center align-items-center cursorPointer"
                :class="
                  maxLeadInputsCheck('email') ? 'div-disable' : 'cursorPointer'
                "
                v-b-tooltip.hover
                :title="`Ask Emails which are validated by Quizell! `"
                @click="addLeadInputData(`email`)"
              >
                <b-icon icon="envelope-open-fill"></b-icon>

                <p class="my-1">Email</p>
              </div>
            </div>
            <div
              class="col-4 col-xl-4 col-lg-6 col-xs-12 col-md-6 col-sm-12 mb-4"
            >
              <div
                class="inputBxShadow d-flex flex-column rounded p-3 justify-content-center align-items-center cursorPointer"
                :class="
                  maxLeadInputsCheck('date') ? 'div-disable' : 'cursorPointer'
                "
                v-b-tooltip.hover
                :title="`Pick Date from Calender or insert manually `"
                @click="addLeadInputData(`date`)"
              >
                <b-icon icon="calendar-date-fill"></b-icon>

                <p class="my-1">Date</p>
              </div>
            </div>
            <div
              class="col-4 col-xl-4 col-lg-6 col-xs-12 col-md-6 col-sm-12 mb-4"
            >
              <div
                class="inputBxShadow d-flex flex-column rounded p-3 justify-content-center align-items-center cursorPointer"
                :class="
                  maxLeadInputsCheck('website')
                    ? 'div-disable'
                    : 'cursorPointer'
                "
                v-b-tooltip.hover
                :title="`Ask Website as text `"
                @click="addLeadInputData(`website`)"
              >
                <b-icon icon="globe"></b-icon>

                <p class="my-1">Website</p>
              </div>
            </div>
            <div
              class="col-4 col-xl-4 col-lg-6 col-xs-12 col-md-6 col-sm-12 mb-4"
            >
              <div
                class="inputBxShadow d-flex flex-column rounded p-3 justify-content-center align-items-center cursorPointer"
                :class="
                  maxLeadInputsCheck('organisation')
                    ? 'div-disable'
                    : 'cursorPointer'
                "
                v-b-tooltip.hover
                :title="`Ask Organisation as text `"
                @click="addLeadInputData(`organisation`)"
              >
                <b-icon icon="building"></b-icon>

                <p class="my-1">Organisation</p>
              </div>
            </div>
          </div>

          <div class="row w-100 m-0">
            <div class="col-12 pb-3">
              <p
                class="font-weight-bold pb-2"
                style="border-bottom: 1px solid #b3afb6"
              >
                Custom Input Fields
              </p>
            </div>

            <div v-if="customInputFields.length" class="row m-0 w-100">
              <div
                class="col-4 col-xl-4 col-lg-6 col-xs-12 col-md-6 col-sm-12 mb-4"
                v-for="(customInput, index) in customInputFields"
                :key="index"
              >
                <div
                  class="inputBxShadow d-flex flex-column rounded p-3 justify-content-center align-items-center cursorPointer"
                  :class="
                    maxLeadInputsCheck(customInput.field_name)
                      ? 'div-disable'
                      : 'cursorPointer'
                  "
                  v-b-tooltip.hover
                  :title="`Ask ${customInput.field_name} as ${customInput.field_type} `"
                  @click="addCustomInputFields(customInput)"
                >
                  <b-icon
                    :icon="getCustomIcon(customInput.field_type)"
                  ></b-icon>

                  <p class="my-1">{{ customInput.field_name }}</p>
                </div>
              </div>
              <div
                class="col-4 col-xl-4 col-lg-6 col-xs-12 col-md-6 col-sm-12 mb-4"
              >
                <div
                  class="inputBxShadow d-flex flex-column rounded p-3 justify-content-center align-items-center cursorPointer"
                  @click="toggleCustomInputView('customInputList')"
                >
                  <b-icon icon="plus"></b-icon>

                  <p class="my-1">Add Input</p>
                </div>
              </div>
            </div>
            <div v-else class="row m-0 w-100">
              <div class="col-12 d-flex flex-column align-items-center">
                <p class="font-weight-bold" style="text-align: center">
                  No Custom input created!
                </p>
                <Button @click="toggleCustomInputView('customInputList')">
                  Create New</Button
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="customInputView == 'customInputList'">
        <div class="px-4">
          <CustomInputComp
            @toggleView="toggleCustomInputView('allInputsview')"
          />
        </div>
      </div>
    </modal>
    <!-- Lead Inputs Modal End -->

    <!-- Address Modal Start  -->

    <modal
      ref="addNewAddressCompRef"
      id="addNewAddressCompRef"
      size="md"
      title="<span class='px-4'>Create Address Input</span>"
    >
      <div class="p-2">
        <div class="row px-4">
          <div class="col-12">
            <p class="font-weight-bold">Select input you wish to add!</p>
          </div>

          <div class="col-12">
            <div
              class="d-flex align-items-center justify-content-between"
              :class="
                checkExistingAddresField(`AddressLine1`)
                  ? 'addressCustomInputDisabled'
                  : null
              "
            >
              <p class="m-0 p-0">Address Line 1</p>
              <div class="custom-control custom-switch cursor-pointer">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="checkExistingAddresField(`AddressLine1`)"
                  :id="`AddressLine1InputSwitch`"
                  v-model="selectedAddressInputs.AddressLine1Input"
                />
                <label
                  class="custom-control-label"
                  :for="`AddressLine1InputSwitch`"
                ></label>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div
              class="d-flex align-items-center justify-content-between"
              :class="
                checkExistingAddresField(`AddressLine2`)
                  ? 'addressCustomInputDisabled'
                  : null
              "
            >
              <p class="m-0 p-0">Address Line 2</p>
              <span class="custom-control custom-switch cursor-pointer">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="checkExistingAddresField(`AddressLine2`)"
                  :id="`AddressLine2InputSwitch`"
                  v-model="selectedAddressInputs.AddressLine2Input"
                />
                <label
                  class="custom-control-label"
                  :for="`AddressLine2InputSwitch`"
                ></label>
              </span>
            </div>
          </div>
          <div class="col-12">
            <div
              class="d-flex align-items-center justify-content-between"
              :class="
                checkExistingAddresField(`zipCode`)
                  ? 'addressCustomInputDisabled'
                  : null
              "
            >
              <p class="m-0 p-0">Zip Code</p>
              <span class="custom-control custom-switch cursor-pointer">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="checkExistingAddresField(`zipCode`)"
                  :id="`ZipCodeInputSwitch`"
                  v-model="selectedAddressInputs.ZipCodeInput"
                />
                <label
                  class="custom-control-label"
                  :for="`ZipCodeInputSwitch`"
                ></label>
              </span>
            </div>
          </div>
          <div class="col-12">
            <div
              class="d-flex align-items-center justify-content-between"
              :class="
                checkExistingAddresField(`City`)
                  ? 'addressCustomInputDisabled'
                  : null
              "
            >
              <p class="m-0 p-0">City</p>
              <span class="custom-control custom-switch cursor-pointer">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="checkExistingAddresField(`City`)"
                  :id="`CityInputSwitch`"
                  v-model="selectedAddressInputs.CityInput"
                />
                <label
                  class="custom-control-label"
                  :for="`CityInputSwitch`"
                ></label>
              </span>
            </div>
          </div>
          <div class="col-12">
            <div
              class="d-flex align-items-center justify-content-between"
              :class="
                checkExistingAddresField(`State`)
                  ? 'addressCustomInputDisabled'
                  : null
              "
            >
              <p class="m-0 p-0">State</p>
              <span class="custom-control custom-switch cursor-pointer">
                <input
                  type="checkbox"
                  :disabled="checkExistingAddresField(`State`)"
                  class="custom-control-input"
                  :id="`StateInputSwitch`"
                  v-model="selectedAddressInputs.StateInput"
                />
                <label
                  class="custom-control-label"
                  :for="`StateInputSwitch`"
                ></label>
              </span>
            </div>
          </div>
          <div class="col-12">
            <div
              class="d-flex align-items-center justify-content-between"
              :class="
                checkExistingAddresField(`Country`)
                  ? 'addressCustomInputDisabled'
                  : null
              "
            >
              <p class="m-0 p-0">Country</p>
              <span class="custom-control custom-switch cursor-pointer">
                <input
                  type="checkbox"
                  :disabled="checkExistingAddresField(`Country`)"
                  class="custom-control-input addressCustomInput"
                  :id="`CountryInputSwitch`"
                  v-model="selectedAddressInputs.CountryInput"
                />
                <label
                  class="custom-control-label"
                  :for="`CountryInputSwitch`"
                ></label>
              </span>
            </div>
          </div>
          <div class="col-12 d-flex justify-content-center pt-3">
            <Button @click="createAddressInput"> Create Input</Button>
          </div>
        </div>
      </div>
    </modal>
    <!-- Address Modal End -->
  </div>
</template>

<script>
import centralEventBus from "./centralEventBus";
import PageDesignCustomize from "../customize/pages/StartPage.vue";
import ImageDesignCustomize from "../customize/pages/ImagePage.vue";
import ScriptModal from "../../../Dashboard/modal/AddScriptModal.vue";
import InitialPageValues from "../../staticPages/IntialPageValues";
import Modal from "../../../Dashboard/Layout/Modal.vue";
import Button from "../../../Dashboard/Layout/Button.vue";
import {
  createTextElement,
  createBasicButtonElement,
  createCheckboxElement,
} from "../../../../composibles/createEditorElements";
import { cloneDeep } from "lodash";
import { mapMutations, mapGetters } from "vuex";
export default {
  props: ["CurrentSelectedPage", "isDesktopView"],
  components: {
    PageDesignCustomize,
    ImageDesignCustomize,
    Modal,
    Button,
    ScriptModal,
    CustomInputComp: () =>
      import(/* webpackChunkName: "CustomInputComp" */ "./CustomInputComp.vue"),
    TextBlock: () =>
      import(/* webpackChunkName: "TextBlock" */ "../customize/block/Text.vue"),
    HtmlBlock: () =>
      import(/* webpackChunkName: "HtmlBlock" */ "../customize/block/Html.vue"),
    ButtonBlock: () =>
      import(
        /* webpackChunkName: "ButtonBlock" */ "../customize/block/Button.vue"
      ),
    TimerBlock: () =>
      import(
        /* webpackChunkName: "TimerBlock" */ "../customize/block/Timer.vue"
      ),
    ToolTipEditBlock: () =>
      import(
        /* webpackChunkName: "ToolTipEditBlock" */ "../customize/block/ToolTipEditBlock.vue"
      ),
    QuestionOptionsBlock: () =>
      import(
        /* webpackChunkName: "QuestionOptionsBlock" */ "../customize/block/QuestionOptionsBlock.vue"
      ),
    BackNextButton: () =>
      import(
        /* webpackChunkName: "BackNextButton" */ "../customize/block/BackNextButton.vue"
      ),
    LeadCheckboxBlock: () =>
      import(
        /* webpackChunkName: "LeadCheckboxBlock" */ "../customize/block/LeadCheckboxBlock.vue"
      ),
    FileUploadBlock: () =>
      import(
        /* webpackChunkName: "FileUploadBlock" */ "../customize/block/FileUploadBlock.vue"
      ),
    FreeTextEdit: () =>
      import(
        /* webpackChunkName: "FreeTextEdit" */ "../customize/block/FreeTextEdit.vue"
      ),
    AuthLeadBlock: () =>
      import(
        /* webpackChunkName: "AuthLeadBlock" */ "../customize/block/AuthLeadBlock.vue"
      ),
    AddressBlock: () =>
      import(
        /* webpackChunkName: "AddressBlock" */ "../customize/block/AddressBlock.vue"
      ),
    LeadFreeTextBlock: () =>
      import(
        /* webpackChunkName: "LeadFreeTextBlock" */ "../customize/block/LeadFreeTextBlock.vue"
      ),
    CustomFieldBlock: () =>
      import(
        /* webpackChunkName: "CustomFieldBlock" */ "../customize/block/CustomFieldBlock.vue"
      ),
    StripePrice: () =>
      import(
        /* webpackChunkName: "StripePrice" */ "../customize/block/StripePrice.vue"
      ),
    SocialLinksEdit: () =>
      import(
        /* webpackChunkName: "SocialLinksEdit" */ "../customize/block/SocialLinksEdit.vue"
      ),
    ShareLinksEdit: () =>
      import(
        /* webpackChunkName: "ShareLinksEdit" */ "../customize/block/ShareLinksEdit.vue"
      ),
    CouponEdit: () =>
      import(
        /* webpackChunkName: "CouponEdit" */ "../customize/block/CouponEdit.vue"
      ),
    ProductBlock: () =>
      import(
        /* webpackChunkName: "ProductBlock" */ "../customize/block/ProductSelectAuto.vue"
      ),
    PaymentButtonBlock: () =>
      import(
        /* webpackChunkName: "PaymentButtonBlock" */ "../customize/block/PaymentButtonBlock.vue"
      ),
    SliderInputBlock: () =>
      import(
        /* webpackChunkName: "SliderInputBlock" */ "../customize/block/SliderInputBlock.vue"
      ),
    ImageCompBlock: () =>
      import(
        /* webpackChunkName: "ImageCompBlock" */ "../customize/block/ImageCompBlock.vue"
      ),
  },
  data() {
    return {
      customInputView: "allInputsview",
      editMode: false,
      pageDesign: {},
      activeSideBar: "pageStyle",

      staticButtonStyles: {
        hoverStatus: false,
        customizeStatus: false,
      },

      //   SingleAnswer/MultipleAns Page Values
      QuestionPageType: null,
      IsMasterQuestion: null,
      skip_status: null,
      shuffleOptions: false,
      resultPageType: null,
      UpdateCount: 0,
      selectedAddressInputs: {
        AddressLine1Input: false,
        AddressLine2Input: false,
        CityInput: false,
        StateInput: false,
        ZipCodeInput: false,
        CountryInput: false,
      },
      customInputFields: [],
      customInputFieldsLoading: false,
    };
  },
  methods: {
    startDrag(evt, item) {
      localStorage.setItem("DragStart", true);
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("item", item);
    },
    checkIsLastElementBackNextBtn(arr) {
      if (arr.length === 0) {
        return false;
      }
      var lastElement = arr[arr.length - 1];
      return (
        lastElement.type === "button" &&
        lastElement.content &&
        "backButton" in lastElement.content
      );
    },
    ReArrangeQuizElement() {
      let { pageDesign, value } = this.getSelectedPage;
      let BackNextBtnPages = [
        "singleAnswer",
        "multipleAnswer",
        "leadPage",
        "stripePage",
      ];
      let { blocksArray } = pageDesign;

      if (BackNextBtnPages.includes(value)) {
        if (!this.checkIsLastElementBackNextBtn(blocksArray)) {
          const BlockArrayElement = [...blocksArray];

          let buttonIndex = BlockArrayElement.findIndex(
            (element) =>
              element.type === "button" &&
              element.content &&
              "backButton" in element.content
          );

          if (
            buttonIndex !== -1 &&
            buttonIndex !== BlockArrayElement.length - 1
          ) {
            let buttonElement = BlockArrayElement.splice(buttonIndex, 1)[0];
            BlockArrayElement.push(buttonElement);
            this.pageDesign.blocksArray = BlockArrayElement;
          }
        }
      }
    },
    toggleCustomInputView(view) {
      if (view == "allInputsview") {
        this.customInputView = view;
        this.fetchCustomInputFields();
      } else {
        this.customInputView = view;
      }
    },
    getCustomIcon(type) {
      let icon = "";

      if (type == "text") icon = "fonts";
      if (type == "textarea") icon = "textarea-t";
      if (type == "date") icon = "calendar-date-fill";
      return icon;
    },
    openInputFieldsModal() {
      this.customInputFields = [];
      this.$refs.addNewInputCompRef.open();
      this.fetchCustomInputFields();
    },
    async fetchCustomInputFields() {
      try {
        this.customInputFieldsLoading = true;

        const response = await this.$store.dispatch("fetchData", {
          axiosMethod: "get",
          resource: "customInputs",
          method: "list",
        });
        this.customInputFields = response.data;
        this.$store.commit("updateUserCustomLeadIputs", this.customInputFields);
        //updateUserCustomLeadIputs
      } catch (error) {
        this.$toasted.show("Error occured ", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        throw error;
      } finally {
        this.customInputFieldsLoading = false;
      }
    },
    showComponent(typeArr) {
      if (typeArr.includes("all")) return true;
      if (typeArr.includes(this.CurrentSelectedPage.value)) return true;
      return false;
      // if(type == 'all') return true
      // if(type == this.CurrentSelectedPage.value) return true
      // return false
    },
    ...mapMutations([
      "setUpdatePageValues",
      "setImageUpload",
      "setUpdatePageQuestionPageType",
      "setUpdatePageSkipStatus",
      "setUpdatePageisMasterQuestion",
      "setCurrSidebarView",
    ]),
    getPageDesignValues() {
      this.pageDesign = this.CurrentSelectedPage.pageDesign;

      // Add image Variable Width if not exist
      if (
        !Object.prototype.hasOwnProperty.call(
          this.pageDesign.imageBlock,
          "useImageVariableWidth"
        )
      ) {
        this.pageDesign.imageBlock.useImageVariableWidth = false;
        this.pageDesign.imageBlock.imageVariableWidth = 50;
        this.pageDesign.imageBlock.imageVariableMobileHeight = 50;
      }
      if (
        !Object.prototype.hasOwnProperty.call(
          this.pageDesign.imageBlock.style,
          "flipImage"
        )
      ) {
        this.pageDesign.imageBlock.style.flipImage = false;
      }
      if (
        !Object.prototype.hasOwnProperty.call(
          this.pageDesign.imageBlock,
          "useMobileImage"
        )
      ) {
        // this.pageDesign.imageBlock.useMobileImage = false;
        this.$set(this.pageDesign.imageBlock, "useMobileImage", false);
        this.$set(
          this.pageDesign.imageBlock,
          "useMobileImageSrc",
          "/images/default_start_page.png"
        );
      }
      if (
        !Object.prototype.hasOwnProperty.call(
          this.pageDesign.pageStyles,
          "useMobileImageAlign"
        )
      ) {
        this.$set(this.pageDesign.pageStyles, "useMobileImageAlign", false);
        this.$set(
          this.pageDesign.pageStyles,
          "mobileImageAlign",
          "left"
        );
      }

      //   SingleAns/MultipleAns Comp Functions Starts

      if (
        this.CurrentSelectedPage.value == "singleAnswer" ||
        this.CurrentSelectedPage.value == "multipleAnswer"
      ) {
        this.IsMasterQuestion = this.CurrentSelectedPage.isMasterQuestion;
        this.skip_status = this.CurrentSelectedPage.skip_status;
        this.shuffleOptions = Object.prototype.hasOwnProperty.call(
          this.CurrentSelectedPage.pageDesign.blocksArray[this.getOptionIndex],
          "shuffleOptions"
        )
          ? this.CurrentSelectedPage.pageDesign.blocksArray[this.getOptionIndex]
              .shuffleOptions
          : false;
        this.QuestionPageType = this.CurrentSelectedPage.value;

        let OptionIndex = this.getOptionIndex;
        if (
          OptionIndex &&
          !this.pageDesign.blocksArray[OptionIndex].style.spaceBetweenRow
        ) {
          this.pageDesign.blocksArray[OptionIndex].style.spaceBetweenRow = "10";
        }

        if (
          OptionIndex &&
          !this.pageDesign.blocksArray[OptionIndex].limitSelection
        ) {
          let obj = {
            limitSelectionValue: false,
            limitSelectionType: "exact",
            exactSelection: 1,
            minRangeSelection: 1,
            maxRangeSelection: 2,
          };

          this.pageDesign.blocksArray[OptionIndex].limitSelection = obj;
        }

        if (
          OptionIndex &&
          !this.pageDesign.blocksArray[OptionIndex].style.imageMobileWidth
        ) {
          this.pageDesign.blocksArray[OptionIndex].style.imageMobileWidth =
            this.pageDesign.blocksArray[OptionIndex].style.imageWidth;
        }
        if (
          OptionIndex &&
          !this.pageDesign.blocksArray[OptionIndex].style.imageMobileHeight
        ) {
          this.pageDesign.blocksArray[OptionIndex].style.imageMobileHeight =
            this.pageDesign.blocksArray[OptionIndex].style.imageHeight;
        }

        // Add min width & max width to tab answer inputs
        if (
          OptionIndex &&
          !Object.prototype.hasOwnProperty.call(
            this.pageDesign.blocksArray[OptionIndex].style.defaultTab,
            `minTabWidthDesktop`
          )
        ) {
          this.pageDesign.blocksArray[
            OptionIndex
          ].style.defaultTab.minTabWidthDesktop = 200;
        }
        if (
          OptionIndex &&
          !Object.prototype.hasOwnProperty.call(
            this.pageDesign.blocksArray[OptionIndex].style.defaultTab,
            `maxTabWidthDesktop`
          )
        ) {
          this.pageDesign.blocksArray[
            OptionIndex
          ].style.defaultTab.maxTabWidthDesktop = 500;
        }
        if (
          OptionIndex &&
          !Object.prototype.hasOwnProperty.call(
            this.pageDesign.blocksArray[OptionIndex].style.defaultTab,
            `minTabWidthMobile`
          )
        ) {
          this.pageDesign.blocksArray[
            OptionIndex
          ].style.defaultTab.minTabWidthMobile = 200;
        }
        if (
          OptionIndex &&
          !Object.prototype.hasOwnProperty.call(
            this.pageDesign.blocksArray[OptionIndex].style.defaultTab,
            `maxTabWidthMobile`
          )
        ) {
          this.pageDesign.blocksArray[
            OptionIndex
          ].style.defaultTab.maxTabWidthMobile = 500;
        }
        if (
          OptionIndex &&
          !Object.prototype.hasOwnProperty.call(
            this.pageDesign.blocksArray[OptionIndex].optionSetting,
            `mobileOptionsView`
          )
        ) {
          this.pageDesign.blocksArray[
            OptionIndex
          ].optionSetting.mobileOptionsView = null;
        }

        if (
          OptionIndex &&
          !Object.prototype.hasOwnProperty.call(
            this.pageDesign.blocksArray[OptionIndex],
            `mobileTextOptionAlignment`
          )
        ) {
          this.$set(
            this.pageDesign.blocksArray[OptionIndex],
            "mobileTextOptionAlignment",
            "vertical"
          );
          this.$set(
            this.pageDesign.blocksArray[OptionIndex],
            "useSeperateOptionAlignment",
            false
          );
        }
      }

      //   SingleAns/MultipleAns Comp Functions Ends

      // Lead Page Function Starts
      if (this.CurrentSelectedPage.value == "leadPage") {
        let indexes = this.getAllIndexes(
          this.pageDesign.blocksArray,
          "freeText"
        );
        this.updateFreeTextValues(indexes);

        let CheckboxIndexes = this.getAllIndexes(
          this.pageDesign.blocksArray,
          "checkbox"
        );

        this.updateCheckBoxRequired(CheckboxIndexes);

        let DateIndex = this.getDateIndex;
        if (DateIndex > -1) {
          if (
            !this.pageDesign.blocksArray[DateIndex].selectedInfoType
              .datePickerValue
          ) {
            this.pageDesign.blocksArray[
              DateIndex
            ].selectedInfoType.datePickerValue = "";
            //  this.pageDesign.blocksArray[DateIndex].selectedInfoType.datePickerPlaceholder="No date selected"
          }
        }

        let EmailIndex = this.infoTypeEmailIndex;
        if (EmailIndex) {
          if (
            !this.pageDesign.blocksArray[EmailIndex].selectedInfoType.enableOTP
          ) {
            this.pageDesign.blocksArray[
              EmailIndex
            ].selectedInfoType.enableOTP = false;
          }
        }

        let freeTextAreaIndexArr = this.getFreeTextAreaInputIndexArr;

        if (freeTextAreaIndexArr.length > 0) {
          freeTextAreaIndexArr.forEach((index) => {
            if (
              !Object.prototype.hasOwnProperty.call(
                this.pageDesign.blocksArray[index],
                `limitFreeTextArea`
              )
            ) {
              let obj = {
                limitFreeTextAreaValue: false,
                limitSelectionType: "max",
                exactFreeTextAreaValue: 20,
                minFreeTextAreaValue: 10,
                maxFreeTextAreaValue: 100,
              };

              this.pageDesign.blocksArray[index].limitFreeTextArea = obj;
            }
          });
        }
      }
      // Lead Page Function Ends

      // StripePage Functions Start
      if (this.CurrentSelectedPage.value == "stripePage") {
        let priceIndex = this.getStripePriceObjIndex;
        if (priceIndex) {
          if (
            this.pageDesign.blocksArray[priceIndex].selectedCurrency
              .currencyCode == "GBP" &&
            this.pageDesign.blocksArray[priceIndex].selectedCurrency
              .currencySymbol == "€"
          ) {
            this.pageDesign.blocksArray[
              priceIndex
            ].selectedCurrency.currencySymbol = "£";
          }

          if (
            this.pageDesign.blocksArray[priceIndex].Currencies[1]
              .currencyCode == "GBP" &&
            this.pageDesign.blocksArray[priceIndex].Currencies[1]
              .currencySymbol == "€"
          ) {
            this.pageDesign.blocksArray[
              priceIndex
            ].Currencies[1].currencySymbol = "£";
          }
          if (
            !Object.prototype.hasOwnProperty.call(
              this.pageDesign.blocksArray[priceIndex],
              "paypalPaymentDetail"
            )
          ) {
            this.$set(
              this.pageDesign.blocksArray[priceIndex],
              "paypalPaymentDetail",
              {}
            );
            this.$set(
              this.pageDesign.blocksArray[priceIndex],
              "paypalClientID",
              ""
            );
            this.$set(
              this.pageDesign.blocksArray[priceIndex],
              "selectedPaymentMethod",
              `Stripe`
            );
            this.$set(
              this.pageDesign.blocksArray[priceIndex],
              "RecurringPayment",
              [
                { id: 1, value: "month", Text: "Monthly" },
                { id: 2, value: "year", Text: "Yearly" },
                { id: 3, value: "day", Text: "Daily" },
              ]
            );
          }
        }

        let paymentButtonIndex = this.getPricePaymentButtonIndex;

        if (paymentButtonIndex) {
          if (
            !Object.prototype.hasOwnProperty.call(
              this.pageDesign.blocksArray[paymentButtonIndex],
              "paypalButtonData"
            )
          ) {
            this.$set(
              this.pageDesign.blocksArray[paymentButtonIndex],
              "paypalButtonData",
              {
                shape: "rect",
                color: "gold",
                layout: "vertical",
                label: "paypal",
              }
            );
          }
        }
      }
      // StripePage Functions End

      // ResultPage Start
      if (this.CurrentSelectedPage.value == "resultPage") {
        this.resultPageType = localStorage.getItem("default_result_Page_state");
        this.pageDesign.pageType = localStorage.getItem(
          "default_result_Page_state"
        );

        // if ProductRankOption array is not present then add the array.
        const ProductRankOptionIndex = this.getProductRankOptionArrayIndex;
        if (ProductRankOptionIndex) {
          const block = this.pageDesign.blocksArray[ProductRankOptionIndex];

          // View Type for Mobile
          if (!Object.prototype.hasOwnProperty.call(block, "mobileViewType")) {
            this.$set(
              this.pageDesign.blocksArray[ProductRankOptionIndex],
              "mobileViewType",
              "list"
            );
            this.$set(
              this.pageDesign.blocksArray[ProductRankOptionIndex],
              "useSeperateMobileViewType",
              false
            );
          }


          // if(!block.hasOwnProperty('productRankOption')){
          if (
            !Object.prototype.hasOwnProperty.call(block, "productRankOption")
          ) {
            const rankOption = this.createRankOptionArray;
            this.pageDesign.blocksArray[
              ProductRankOptionIndex
            ].productRankOption = rankOption;
          }
          if (!Object.prototype.hasOwnProperty.call(block, "mobileRows")) {
            this.pageDesign.blocksArray[ProductRankOptionIndex].mobileRows = 10;
          }
          // if(!limitDescription){
          if (
            !Object.prototype.hasOwnProperty.call(block, "limitDescription")
          ) {
            this.pageDesign.blocksArray[
              ProductRankOptionIndex
            ].limitDescription = false;
          }

          if (
            !Object.prototype.hasOwnProperty.call(
              this.pageDesign.imageBlock,
              "noResultSrc"
            )
          ) {
            this.pageDesign.imageBlock.noResultSrc = "";
          }

          if (
            !Object.prototype.hasOwnProperty.call(block, "selectedBulletIcon")
          ) {
            this.pageDesign.blocksArray[
              ProductRankOptionIndex
            ].selectedBulletIcon = "fa-check-circle";
          }
          if (!Object.prototype.hasOwnProperty.call(block, "useAddToCart")) {
            this.pageDesign.blocksArray[
              ProductRankOptionIndex
            ].useAddToCart = false;
            this.pageDesign.blocksArray[
              ProductRankOptionIndex
            ].useAddToCartText = "Add to Cart";
            this.pageDesign.blocksArray[
              ProductRankOptionIndex
            ].selectVariantText = "Select Variant";
            this.pageDesign.blocksArray[
              ProductRankOptionIndex
            ].useProductVariant = false;

            if (
              !Object.prototype.hasOwnProperty.call(
                this.pageDesign.blocksArray[ProductRankOptionIndex].style,
                "productVariantStyles"
              )
            ) {
              this.pageDesign.blocksArray[
                ProductRankOptionIndex
              ].style.productVariantStyles = {
                background: "#000000",
                color: "#fff",
                fontSize: 15,
                fontFamily: "Poppins",
                height: "32",
                lineHeight: 2,
                borderRadius: 4,
                arrowBackground: "#5c6664",
                arrowColor: "#ffffff",
              };
            }
          }

          let bulletDescObj = {
            bulletDescriptionfontFamily: "Poppins",
            bulletDescriptionfontSize: 15,
            bulletDescriptionfontWeight: "normal",
            bulletDescriptionfontStyle: "normal",
            bulletDescriptiontextDecoration: "unset",
            bulletDescriptiontitleColor: "#000",
            bulletDescriptiontitleTextOpacity: 100,
            bulletDescriptiontextAlign: "left",
          };

          let isBulletObjExist = this.hasKeyValuePairs(
            this.pageDesign.blocksArray[ProductRankOptionIndex].style,
            bulletDescObj
          );

          if (!isBulletObjExist) {
            for (const [key, value] of Object.entries(bulletDescObj)) {
              if (
                !(
                  key in
                  this.pageDesign.blocksArray[ProductRankOptionIndex].style
                )
              ) {
                this.pageDesign.blocksArray[ProductRankOptionIndex].style[key] =
                  value;
              }
            }
          }

          // Group Products MultipleView Starts
          if (!Object.prototype.hasOwnProperty.call(block, "groupProducts")) {
            this.$set(
              this.pageDesign.blocksArray[ProductRankOptionIndex],
              "groupProducts",
              {
                groupBy: "Tags",
                limitProductsPerGroup: false,
                productsPerGroup: 5,
                groupData: [],
              }
            );
          }
          if (
            !Object.prototype.hasOwnProperty.call(
              block.style,
              "groupProductsStyle"
            )
          ) {
            this.$set(
              this.pageDesign.blocksArray[ProductRankOptionIndex].style,
              "groupProductsStyle",
              {
                textAlign: "center",
                fontSize: 20,
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "bold",
                textDecoration: "unset",
                color: "#000",
              }
            );
          }
          // Group Products MultipleView Ends

          if (
            !Object.prototype.hasOwnProperty.call(
              block.style,
              "buttonDesktopFontSize"
            )
          ) {
            this.$set(
              this.pageDesign.blocksArray[ProductRankOptionIndex].style,
              "buttonDesktopFontSize",
              16
            );
            this.$set(
              this.pageDesign.blocksArray[ProductRankOptionIndex].style,
              "buttonMobileFontSize",
              16
            );
          }
          if (
            !Object.prototype.hasOwnProperty.call(
              block.style,
              "buttonFontFamily"
            )
          ) {
            this.$set(
              this.pageDesign.blocksArray[ProductRankOptionIndex].style,
              "buttonFontFamily",
              this.pageDesign.blocksArray[ProductRankOptionIndex].style
                .fontFamily
            );
          }
        }

        const SocialLinksIndex = this.getSocialLinksIndex;
        if (SocialLinksIndex) {
          let originalObj = this.pageDesign.blocksArray[SocialLinksIndex];

          let tiktokPinterestObj = {
            isTikTokVisible: false,
            isPinterestVisible: false,
            tikTokValue: "",
            pinterestValue: "",
            tikTokPlaceholder: "https://www.tiktok.com/profile",
            pinterestPlaceholder: "https://www.pinterest.com/profile",
          };

          // Check if key value pairs exist or not

          let result = this.hasKeyValuePairs(originalObj, tiktokPinterestObj);

          if (!result) {
            for (const [key, value] of Object.entries(tiktokPinterestObj)) {
              if (!(key in this.pageDesign.blocksArray[SocialLinksIndex])) {
                this.pageDesign.blocksArray[SocialLinksIndex][key] = value;
              }
            }
          }
        }
      }
      // ResultPage End

      if (Object.entries(this.pageDesign).length !== 0) {
        this.backToMainPage();
      }
    },

    editImage() {
      this.activeSideBar = "ImageStyle";
      this.editMode = true;
    },
    backToMainPage() {
      this.editMode = false;
      this.activeSideBar = "pageStyle";
      this.pageDesign.pageStyles.activeSubBlock = false;
      this.removeSubActive();
    },
    removeSubActive() {
      if (
        this.CurrentSelectedPage.value == "resultPage" &&
        this.getResultPageType == "noResultPage"
      ) {
        this.pageDesign.NoResultBlocksArray.forEach((obj) => {
          obj.customizeStatus = false;
          obj.hoverStatus = false;
        });
      } else {
        this.pageDesign.blocksArray.forEach((obj) => {
          obj.customizeStatus = false;
          obj.hoverStatus = false;
        });
      }

      this.pageDesign.imageBlock.customizeStatus = false;
      this.staticButtonStyles.hoverStatus = false;
      this.staticButtonStyles.customizeStatus = false;
    },
    removeBgImage() {
      this.pageDesign.imageBlock.src = "";
      this.pageDesign.pageStyles.imageAlign = "left";
      this.pageDesign.imageBlock.style.displayMobile = false;
      this.pageDesign.imageBlock.style.displayDesktop = false;

      this.backToMainPage();
    },

    handleAddHover(index) {
      if (
        this.CurrentSelectedPage.value == "resultPage" &&
        this.getResultPageType == "noResultPage"
      ) {
        if (
          this.pageDesign.NoResultBlocksArray[index].customizeStatus == false
        ) {
          this.pageDesign.NoResultBlocksArray[index].hoverStatus = true;
        }
      } else {
        if (this.pageDesign.blocksArray[index].customizeStatus == false)
          this.pageDesign.blocksArray[index].hoverStatus = true;
      }
    },
    handleRemoveHover(index) {
      if (
        this.CurrentSelectedPage.value == "resultPage" &&
        this.getResultPageType == "noResultPage"
      ) {
        this.pageDesign.NoResultBlocksArray[index].hoverStatus = false;
      } else {
        this.pageDesign.blocksArray[index].hoverStatus = false;
      }
    },
    handleAddStaticHover(obj) {
      this.staticButtonStyles = obj;
    },
    handleRemoveStaticHover(obj) {
      this.staticButtonStyles = obj;
    },
    handleChangeCustomize(index) {
      this.editMode = true;

      if (
        this.CurrentSelectedPage.value == "resultPage" &&
        this.getResultPageType == "noResultPage"
      ) {
        this.pageDesign.NoResultBlocksArray.forEach((obj) => {
          obj.customizeStatus = false;
          obj.hoverStatus = false;
        });
        this.pageDesign.imageBlock.customizeStatus = false;
        this.pageDesign.NoResultBlocksArray[index].customizeStatus = true;
        this.pageDesign.pageStyles.activeSubBlock = true;
        this.activeSideBar = "pageStyle";
      } else {
        this.pageDesign.blocksArray.forEach((obj) => {
          obj.customizeStatus = false;
          obj.hoverStatus = false;
        });
        this.pageDesign.imageBlock.customizeStatus = false;
        this.staticButtonStyles.customizeStatus = false;
        this.pageDesign.blocksArray[index].customizeStatus = true;
        this.pageDesign.pageStyles.activeSubBlock = true;
        this.activeSideBar = "pageStyle";
      }

      this.setCurrSidebarView("pageDesign");
    },
    handleStaticCustomize(index) {
      this.editMode = true;
      this.pageDesign.blocksArray.forEach((obj) => {
        obj.customizeStatus = false;
        obj.hoverStatus = false;
      });
      this.pageDesign.imageBlock.customizeStatus = false;
      this.pageDesign.blocksArray[index].customizeStatus = false;

      this.staticButtonStyles.customizeStatus = true;
      this.pageDesign.pageStyles.activeSubBlock = true;
      this.activeSideBar = "pageStyle";
    },
    handleImageCustomize() {
      this.editMode = true;
      this.activeSideBar = "ImageStyle";
      this.pageDesign.blocksArray.forEach((obj) => {
        obj.customizeStatus = false;
        obj.hoverStatus = false;
      });
      this.staticButtonStyles.customizeStatus = false;
      this.pageDesign.imageBlock.customizeStatus = true;
    },
    handleDeleteImage() {
      var x, i;
      x = document.querySelectorAll(`.pic-${this.getSelectedPage.order}`);
      for (i = 0; i < x.length; i++) {
        x[i].src = "";
      }

      if (
        this.CurrentSelectedPage.value == "resultPage" &&
        this.getResultPageType == "noResultPage"
      ) {
        this.pageDesign.imageBlock.noResultSrc = "";
      } else {
        this.pageDesign.imageBlock.src = "";
      }

      this.setImageUpload(null);
      this.pageDesign.imageBlock.style.displayMobile = false;
      this.pageDesign.imageBlock.style.displayDesktop = false;
      this.backToMainPage();
    },
    handleDeleteBlock(index) {
      if (
        this.CurrentSelectedPage.value == "resultPage" &&
        this.getResultPageType == "noResultPage"
      ) {
        this.pageDesign.NoResultBlocksArray.splice(index, 1);
      } else {
        this.pageDesign.blocksArray.splice(index, 1);
      }

      this.editMode = false;
      this.backToMainPage();
    },
    handleBlocksArrayDraggable(newArr) {
      /* let maxLength  = [...this.pageDesign.blocksArray].length

      if (newArr.length < maxLength ) {
        for (let i = 0; i < newArr.length; i++) {
          this.pageDesign.blocksArray[i] = newArr[i];
    }
    }
    */

      this.pageDesign.blocksArray = newArr;
    },
    handleNoResultBlocksArrayDraggable(value) {
      this.pageDesign.NoResultBlocksArray = value;
    },

    maxAddTimer() {
      let count = 0;
      this.pageDesign.blocksArray.forEach((x) => x.type == "timer" && count++);
      return count;
    },
    maxAddSkip() {
      let count = 0;
      this.pageDesign.blocksArray.forEach(
        (x) => x.type == "skipButton" && count++
      );
      return count;
    },
    maxAuth() {
      let count = 0;
      this.pageDesign.blocksArray.forEach((x) => x.type == "Auth" && count++);
      return count;
    },

    saveScript(scriptCode) {
      this.pageDesign.pageStyles.jsCode = scriptCode;
    },

    addButton() {
      if (this.CurrentSelectedPage.value == "resultPage") {
        if (
          this.CurrentSelectedPage.value == "resultPage" &&
          this.getResultPageType == "noResultPage"
        ) {
          const obj = createBasicButtonElement();

          this.pageDesign.NoResultBlocksArray.push(obj);
          return;
        } else {
          const obj = createBasicButtonElement();
          this.pageDesign.blocksArray.push(obj);
        }
      } else {
        const obj = createBasicButtonElement();

        const backNextBtnobj = {
          type: "button",
          content: {
            backButton: {
              text: "Back",
              hideBackButton: false,
              style: {
                backgroundColor: "#b2afb6",
                color: "#ffffff",
                buttonWidth: 100,
                buttonHeight: 56,

                textOpacity: 100,
                backgroundOpacity: 100,

                textDecoration: "unset",
                fontFamily: "Poppins",
                fontWeight: "normal",
                fontStyle: "normal",

                fontSize: 14,

                borderRadius: 8,
                borderSize: 0,
                borderColor: "",
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 15,
                paddingBottom: 15,
                textAlign: "initial",
              },
            },
            nextButton: {
              text: "Next",
              style: {
                backgroundColor: "#0397CC",
                color: "#ffffff",
                buttonWidth: 100,
                buttonHeight: 56,

                textOpacity: 100,
                backgroundOpacity: 100,

                textDecoration: "unset",
                fontFamily: "Poppins",
                fontWeight: "normal",
                fontStyle: "normal",

                fontSize: 14,

                borderRadius: 8,
                borderSize: 0,
                borderColor: "",
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 15,
                paddingBottom: 15,
                textAlign: "initial",
              },
            },
          },

          style: {
            position: "left",
            marginTop: 10,
            marginBottom: 10,
            marginLeft: 10,
            marginRight: 10,
            lineHeight: 1,
          },
          customizeStatus: false,
          hoverStatus: false,
        };

        let backNextBtnPages = ["singleAnswer", "multipleAnswer", "leadPage"];

        if (backNextBtnPages.includes(this.CurrentSelectedPage.value)) {
          this.pageDesign.blocksArray.push(backNextBtnobj);
        } else {
          this.pageDesign.blocksArray.push(obj);
        }
      }
    },
    addNewText() {
      const obj = createTextElement();

      if (
        this.CurrentSelectedPage.value == "resultPage" &&
        this.getResultPageType == "noResultPage"
      ) {
        this.pageDesign.NoResultBlocksArray.push(obj);
        return;
      }
      this.pageDesign.blocksArray.push(obj);
    },
    addHtmlElement() {
      const obj = Object.assign({}, InitialPageValues.htmlElementDesign);

      if (
        this.CurrentSelectedPage.value == "resultPage" &&
        this.getResultPageType == "noResultPage"
      ) {
        this.pageDesign.NoResultBlocksArray.push(obj);
        return;
      }
      this.pageDesign.blocksArray.push(obj);
    },
    addInlineImageElement() {
      const obj = Object.assign({}, InitialPageValues.ImageCompDesign);

      if (
        this.CurrentSelectedPage.value == "resultPage" &&
        this.getResultPageType == "noResultPage"
      ) {
        this.pageDesign.NoResultBlocksArray.push(obj);
        return;
      }
      this.pageDesign.blocksArray.push(obj);
    },
    addTimer() {
      let allowedPlan = ["Pro", "Plus", "Enterprise plan Special"];
      if (allowedPlan.includes(this.UserPlanData.name)) {
        let timerObj = Object.assign({}, InitialPageValues.timerElementDesign);
        this.pageDesign.blocksArray.push(timerObj);
      } else {
        this.$emit("triggerUpgradeModal", {
          title: "Upgrade to Use Quiz Timer",
          subText:
            "Set time limits to add urgency and focus, making quizzes more exciting and interactive. Perfect for a brisk, challenging experience. Upgrade now to activate this dynamic feature.",
        });
      }
    },
    addTooltip() {
      //  let toolTipObj =  InitialPageValues.toolTipDesign
      let toolTipObj = Object.assign({}, InitialPageValues.toolTipDesign);

      this.pageDesign.blocksArray.push(toolTipObj);
    },
    addImage() {
      this.activeSideBar = "ImageStyle";
      this.editMode = true;

      if (
        this.CurrentSelectedPage.value == "resultPage" &&
        this.getResultPageType == "noResultPage"
      ) {
        this.pageDesign.imageBlock.noResultSrc =
          "https://images.quizell.com/website/default_start_page.png";
      } else {
        this.pageDesign.imageBlock.src =
          "https://images.quizell.com/website/default_start_page.png";
      }

      this.pageDesign.imageBlock.style.displayMobile = true;
      this.pageDesign.imageBlock.style.displayDesktop = true;
    },
    addScript() {
      let allowedPlan = ["Pro", "Plus", "Enterprise plan Special"];
      if (allowedPlan.includes(this.UserPlanData.name)) {
        this.$refs.addScriptModal.OpenModal();
      } else {
        this.$emit("triggerUpgradeModal", {
          title: "Upgrade to Use Script Writing",
          subText:
            "Upgrade your plan and unlock the power of Custom Script Writing. With this feature, you can inject your own JavaScript code into your quizzes, opening up a world of customization.",
        });
      }
    },

    // LEad Page Methods
    getAllIndexes(arr, val) {
      var indexes = [],
        i;
      for (i = 0; i < arr.length; i++) if (arr[i].type === val) indexes.push(i);
      return indexes;
    },
    updateCheckBoxRequired(arr) {
      if (!arr.length) return;

      for (let i = 0; i < arr.length; i++) {
        if (
          !Object.prototype.hasOwnProperty.call(
            this.pageDesign.blocksArray[arr[i]],
            "isRequired"
          )
        ) {
          this.pageDesign.blocksArray[arr[i]].isRequired = true;
        }
        if (
          !Object.prototype.hasOwnProperty.call(
            this.pageDesign.blocksArray[arr[i]],
            "preselectCheckbox"
          )
        ) {
          this.pageDesign.blocksArray[arr[i]].preselectCheckbox = false;
        }
      }
    },

    updateFreeTextValues(arr) {
      const obj = {
        text: "Date",
        type: "number",
        fieldName: "date",
        dateText: "Date",
        monthText: "Month",
        yearText: "Year",
        fieldValue: "",
        dateValue: "",
        monthValue: "",
        yearValue: "",
        datePickerValue: "",
        // datePickerPlaceholder:"No date selected"
      };
      for (let i = 0; i < arr.length; i++) {
        // Adding Date Formats start
        if (this.pageDesign.blocksArray[arr[i]].infoType.length <= 3) {
          this.pageDesign.blocksArray[arr[i]].infoType.push(obj);
          this.pageDesign.blocksArray[arr[i]].selectedDateFormat = "DDMMYYYY";
          this.pageDesign.blocksArray[arr[i]].dateFormat = [
            {
              id: 1,
              value: "DDMMYYYY",
            },
            {
              id: 2,
              value: "MMDDYYYY",
            },
            {
              id: 3,
              value: "YYYYMMDD",
            },
          ];
          this.pageDesign.blocksArray[arr[i]].dateFormatSeperator = [
            {
              id: 1,
              value: "/",
            },
            {
              id: 2,
              value: "-",
            },
            {
              id: 3,
              value: ".",
            },
          ];
          this.pageDesign.blocksArray[arr[i]].selectedDateFormatSeperator = "/";

          this.pageDesign.blocksArray[arr[i]].style.DateTextColor = "#000000";
          this.pageDesign.blocksArray[arr[i]].style.DateFontFamily = "Poppins";
          this.pageDesign.blocksArray[arr[i]].style.DateFontSize = 14;
          this.pageDesign.blocksArray[arr[i]].style.DateTextOpacity = 100;
          this.pageDesign.blocksArray[arr[i]].style.DateTextDecoration =
            "unset";
          this.pageDesign.blocksArray[arr[i]].style.DateFontWeight = "bold";
          this.pageDesign.blocksArray[arr[i]].style.DateFontStyle = "normal";
          this.pageDesign.blocksArray[arr[i]].style.DateTextAlign = "left";
        }

        // Adding Date Formats end
        // if MMDD & DDMM date formats are not added
        if (this.pageDesign.blocksArray[arr[i]].dateFormat.length <= 3) {
          const obj = [
            {
              id: 4,
              value: "DDMM",
            },
            {
              id: 5,
              value: "MMDD",
            },
          ];

          this.pageDesign.blocksArray[arr[i]].dateFormat = [
            ...this.pageDesign.blocksArray[arr[i]].dateFormat,
            ...obj,
          ];
        }

        // Adding Website and Organization
        if (this.pageDesign.blocksArray[arr[i]].infoType.length <= 4) {
          let websiteObj = {
            text: "Website",
            type: "text",
            fieldName: "website",
            fieldValue: "",
          };

          let organisationObj = {
            text: "Organization",
            type: "text",
            fieldName: "organisation",
            fieldValue: "",
          };
          this.pageDesign.blocksArray[arr[i]].infoType.push(websiteObj);
          this.pageDesign.blocksArray[arr[i]].infoType.push(organisationObj);
        }
      }
    },

    toggleEnableOTP(e) {
      if (e.target.checked) {
        let obj = InitialPageValues.twoFactAuthInput;
        let index = this.infoTypeEmailIndex;
        if (index) {
          this.pageDesign.blocksArray.splice(index + 1, 0, obj);
        }
      } else {
        let authIndex = this.getOTPAuthIndex;
        if (authIndex) {
          this.pageDesign.blocksArray.splice(authIndex, 1);
        }
      }
    },
    addFileUpload() {
      let allowedPlan = ["Pro", "Plus", "Enterprise plan Special"];
      if (allowedPlan.includes(this.UserPlanData.name)) {
        let fileUploadObj = Object.assign(
          {},
          InitialPageValues.fileUploadDesign
        );

        this.pageDesign.blocksArray.push(fileUploadObj);
      } else {
        this.$emit("triggerUpgradeModal", {
          title: "Upgrade to Enable File Uploads",
          subText:
            "Expand the capabilities of your quizzes by upgrading to include File Uploads. This feature allows quiz participants to easily upload files, enriching their responses and interaction with your content. ",
        });
      }
    },
    addCheckbox() {
      const obj = createCheckboxElement();

      this.pageDesign.blocksArray.push(obj);
    },
    addFreeText() {
      const obj = {
        type: "freeText",
        labelText: "Label",
        showLabel: false,
        isRequired: false,
        addInfoTypeEdit: false,
        newInfoType: "",
        dateFormat: [
          {
            id: 1,
            value: "DDMMYYYY",
          },
          {
            id: 2,
            value: "MMDDYYYY",
          },
          {
            id: 3,
            value: "YYYYMMDD",
          },
          {
            id: 4,
            value: "DDMM",
          },
          {
            id: 5,
            value: "MMDD",
          },
        ],
        dateFormatSeperator: [
          {
            id: 1,
            value: "/",
          },
          {
            id: 2,
            value: "-",
          },
          {
            id: 3,
            value: ".",
          },
        ],
        selectedDateFormat: "DDMMYYYY",
        selectedDateFormatSeperator: "/",
        infoType: [
          {
            text: "Full Name",
            type: "text",
            fieldName: "full_name",
            fieldValue: "",
          },
          {
            text: "Email",
            type: "email",
            fieldName: "email",
            fieldValue: "",
          },
          {
            text: "Phone Number",
            type: "number",
            fieldName: "phone_number",
            fieldValue: "",
          },

          {
            text: "Date",
            type: "number",
            fieldName: "date",
            dateText: "Date",
            monthText: "Month",
            yearText: "Year",
            fieldValue: "",
            dateValue: "",
            monthValue: "",
            yearValue: "",
          },
          {
            text: "Website",
            type: "text",
            fieldName: "website",
            fieldValue: "",
          },
          {
            text: "Organization",
            type: "text",
            fieldName: "organisation",
            fieldValue: "",
          },
        ],
        InfoTypePlaceholder: "Write your input here",
        style: {
          marginTop: 5,
          marginBottom: 5,
          marginLeft: 5,
          marginRight: 5,
          textOpacity: 100,
          color: "#000000",
          fontSize: 14,
          fontFamily: "Poppins",
          strokeOpacity: 100,
          strokeColor: "#ffa201",
          strokeRotation: 0,
          borderSize: 1,
          borderRadius: 0,
          borderType: "border",
          DateTextColor: "#000000",
          DateFontFamily: "Poppins",
          DateFontSize: 14,
          DateTextOpacity: 100,
          DateTextDecoration: "unset",
          DateFontWeight: "bold",
          DateFontStyle: "normal",
          DateTextAlign: "left",
        },
        customizeStatus: false,
        hoverStatus: false,
      };
      // getAllUsedLeadInputs
      if (!this.getAllUsedLeadInputs.includes("full_name")) {
        obj.selectedInfoType = {
          text: "Full Name",
          type: "text",
          fieldName: "full_name",
          fieldValue: "",
        };
      } else if (!this.getAllUsedLeadInputs.includes("email")) {
        obj.selectedInfoType = {
          text: "Email",
          type: "email",
          fieldName: "email",
          fieldValue: "",
          enableOTP: false,
        };
      } else if (!this.getAllUsedLeadInputs.includes("number")) {
        obj.selectedInfoType = {
          text: "Phone Number",
          type: "number",
          fieldName: "phone_number",
          fieldValue: "",
        };
      } else if (!this.getAllUsedLeadInputs.includes("date")) {
        obj.selectedInfoType = {
          text: "Date",
          type: "number",
          fieldName: "date",
          dateText: "Date",
          monthText: "Month",
          yearText: "Year",
          fieldValue: "",
          dateValue: "",
          monthValue: "",
          yearValue: "",
        };
      } else if (!this.getAllUsedLeadInputs.includes("website")) {
        obj.selectedInfoType = {
          text: "Website",
          type: "text",
          fieldName: "website",
          fieldValue: "",
        };
      } else if (!this.getAllUsedLeadInputs.includes("organisation")) {
        obj.selectedInfoType = {
          text: "organisation",
          type: "text",
          fieldName: "organisation",
          fieldValue: "",
        };
      }

      this.pageDesign.blocksArray.push(obj);
    },
    addFreeTextAreaInput() {
      const obj = {
        type: "freeTextArea",
        isRequired: false,
        isMaxCharacter: false,
        maxCharacterLength: 10,
        LeadTextValue: "",
        limitFreeTextArea: {
          limitFreeTextAreaValue: false,
          limitSelectionType: "max",
          exactFreeTextAreaValue: 20,
          minFreeTextAreaValue: 10,
          maxFreeTextAreaValue: 100,
        },
        labelText: "Label",
        showLabel: false,
        InfoTypePlaceholder: "Write your input here",
        style: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 5,
          marginRight: 5,
          textOpacity: 100,
          color: "#000000",
          textDecoration: "unset",
          fontWeight: "normal",
          fontStyle: "normal",
          textAlign: "left",
          fontSize: 14,
          fontFamily: "Poppins",
          strokeOpacity: 100,
          strokeColor: "#CCCACE",
          strokeRotation: 0,
          borderSize: 1,
          borderRadius: 0,
          borderType: "border",
        },
        customizeStatus: false,
        hoverStatus: false,
      };

      this.pageDesign.blocksArray.push(obj);
    },
    addAddressInput() {
      const obj = {
        type: "address",
        isAddressLine1Required: false,
        isAddressLine2Required: false,
        isCityRequired: false,
        isStateRequired: false,
        isZipCodeRequired: false,
        isCountryRequired: false,
        showAddressLine1: true,
        showAddressLine2: true,
        showCity: true,
        showState: true,
        showZipCode: true,
        showCountry: true,
        showAddressLine1Label: true,
        showAddressLine2Label: true,
        showCityLabel: true,
        showStateLabel: true,
        showZipCodeLabel: true,
        showCountryLabel: true,
        AddressLine1Label: "Address",
        AddressLine2Label: "Address Line 2",
        CityLabel: "City/Town",
        StateLabel: "State/Region/Province",
        ZipCodeLabel: "Zip/Post Code",
        CountryLabel: "Country",
        AddressLine1Placeholder: "Address",
        AddressLine2Placeholder: "Address Line 2",
        CityPlaceholder: "City/Town",
        StatePlaceholder: "State/Region/Province",
        ZipCodePlaceholder: "Zip/Post Code",
        CountryPlaceholder: "Country",
        AddressLine1Input: "",
        AddressLine2Input: "",
        CityInput: "",
        StateInput: "",
        ZipCodeInput: "",
        CountryInput: "",

        style: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 5,
          marginRight: 5,
          mobileMarginBottom: 0,
          mobileMarginTop: 0,
          mobileMarginLeft: 0,
          mobileMarginRight: 0,
          borderRadius: 4,
          textOpacity: 100,
          color: "#000000",
          textDecoration: "unset",
          fontWeight: "bold",
          fontStyle: "normal",
          textAlign: "left",
          fontSize: 14,
          fontFamily: "Poppins",
          strokeOpacity: 100,
          strokeColor: "#000000",
          strokeRotation: 0,
          borderSize: 1.5,
          borderType: "border",
          DateTextColor: "#000000",
          DateFontFamily: "Poppins",
          DateFontSize: 14,
          DateTextOpacity: 100,
          DateTextDecoration: "unset",
          DateFontWeight: "bold",
          DateFontStyle: "normal",
          textInputAlign: "left",
          DateTextAlign: "left",
          textInputOpacity: 100,
        },
        customizeStatus: false,
        hoverStatus: false,
      };

      this.pageDesign.blocksArray.push(obj);
    },
    handleDisableOtp() {
      this.pageDesign.blocksArray[
        this.infoTypeEmailIndex
      ].selectedInfoType.enableOTP = false;
    },

    changeSelectedCurrency(currency) {
      let index = this.getPriceCompIndex;
      if (index > -1) {
        this.pageDesign.blocksArray[index].selectedCurrency = currency;
      }
    },
    ChangeRecurringPayment(payment) {
      let index = this.getPriceCompIndex;
      if (index > -1) {
        this.pageDesign.blocksArray[index].selectedRecurringPayment = payment;
      }
    },
    addSkipBtn() {
      let skipObj = Object.assign({}, InitialPageValues.skipBtnDesign);

      this.pageDesign.blocksArray.push(skipObj);
    },
    addOTP() {
      let allowedPlan = ["Pro", "Plus", "Enterprise plan Special"];
      if (allowedPlan.includes(this.UserPlanData.name)) {
        let AuthObj = InitialPageValues.twoFactAuthInput;

        let emailIndex = this.infoTypeEmailIndex;

        if (emailIndex && emailIndex > -1) {
          this.pageDesign.blocksArray.push(AuthObj);
        } else {
          this.addLeadInputData("email");
          this.pageDesign.blocksArray.push(AuthObj);
        }
      } else {
        this.$emit("triggerUpgradeModal", {
          title: "Upgrade for Enhanced Security with OTP",
          subText:
            "Enhance the authenticity of your quiz interactions with our One-Time Password (OTP) feature. This upgrade ensures that the email addresses collected through your quizzes are verified and authentic.",
        });
      }
    },

    // ResultPage Methods
    changePageType(value) {
      this.resultPageType = value;
      localStorage.setItem("default_result_Page_state", value);
      this.removeSubActive();
    },
    addSocialMedia() {
      let socialObj = Object.assign({}, InitialPageValues.socialMediaDesign);

      if (this.getResultPageType == "resultPage") {
        this.pageDesign.blocksArray.push(socialObj);
      } else {
        this.pageDesign.NoResultBlocksArray.push(socialObj);
      }
    },
    addShareLinks() {
      let shareObj = Object.assign({}, InitialPageValues.shareResultLinkDesign);

      if (this.getResultPageType == "resultPage") {
        this.pageDesign.blocksArray.push(shareObj);
      } else {
        this.pageDesign.NoResultBlocksArray.push(shareObj);
      }
    },
    addCouponComp() {
      let couponObj = Object.assign({}, InitialPageValues.couponElementDesign);

      if (this.getResultPageType == "resultPage") {
        this.pageDesign.blocksArray.push(couponObj);
      }
    },
    AddProductRankOption() {
      const productBlockIndex = this.getProductRankOptionArrayIndex;

      const obj = {
        text: "",
      };
      this.pageDesign.blocksArray[productBlockIndex].productRankOption.push(
        obj
      );
      this.UpdateCount++;
    },
    DeleteProductRankOption(index) {
      const productBlockIndex = this.getProductRankOptionArrayIndex;

      this.pageDesign.blocksArray[productBlockIndex].productRankOption.splice(
        index,
        1
      );
      this.UpdateCount++;
    },
    hasKeyValuePairs(obj, keyValuePairs) {
      for (const [key, value] of Object.entries(keyValuePairs)) {
        if (!(key in obj) || obj[key] !== value) {
          return false;
        }
      }

      return true;
    },

    maxLeadInputsCheck(input) {
      if (this.getAllUsedLeadInputs) {
        return this.getAllUsedLeadInputs.includes(input) ? true : false;
      }
      return false;
    },

    addCustomInputFields(input) {
      if (input) {
        const obj = {
          type: "customInput",
          isCustomInput: true,
          labelText: "Label",
          showLabel: false,
          isRequired: false,
          addInfoTypeEdit: false,
          newInfoType: "",
          InfoTypePlaceholder: "Write your input here",
          style: {
            marginTop: 5,
            marginBottom: 5,
            marginLeft: 5,
            marginRight: 5,
            textOpacity: 100,
            color: "#000000",
            fontSize: 14,
            fontFamily: "Poppins",
            strokeOpacity: 100,
            strokeColor: "#ffa201",
            strokeRotation: 0,
            borderSize: 1,
            borderRadius: 0,
            borderType: "border",
            DateTextColor: "#000000",
            DateFontFamily: "Poppins",
            DateFontSize: 14,
            DateTextOpacity: 100,
            DateTextDecoration: "unset",
            DateFontWeight: "bold",
            DateFontStyle: "normal",
            DateTextAlign: "left",
          },
          customizeStatus: false,
          hoverStatus: false,
        };
        // getAllUsedLeadInputs
        if (input.field_type == "text") {
          obj.selectedInfoType = {
            text: input.field_name,
            type: "text",
            fieldName: input.field_name,
            fieldValue: "",
          };
        }
        if (input.field_type == "textarea") {
          obj.selectedInfoType = {
            text: input.field_name,
            type: "textarea",
            fieldName: input.field_name,
            fieldValue: "",
          };
          obj.limitFreeTextArea = {
            limitFreeTextAreaValue: false,
            limitSelectionType: "max",
            exactFreeTextAreaValue: 20,
            minFreeTextAreaValue: 10,
            maxFreeTextAreaValue: 100,
          };
        }
        if (input.field_type == "date") {
          obj.selectedInfoType = {
            text: input.field_name,
            type: input.field_type,
            fieldName: input.field_name,
            dateText: "Date",
            monthText: "Month",
            yearText: "Year",
            fieldValue: "",
            dateValue: "",
            monthValue: "",
            yearValue: "",
          };
          obj["dateFormat"] = [
            {
              id: 1,
              value: "DDMMYYYY",
            },
            {
              id: 2,
              value: "MMDDYYYY",
            },
            {
              id: 3,
              value: "YYYYMMDD",
            },
            {
              id: 4,
              value: "DDMM",
            },
            {
              id: 5,
              value: "MMDD",
            },
          ];
          obj["dateFormatSeperator"] = [
            {
              id: 1,
              value: "/",
            },
            {
              id: 2,
              value: "-",
            },
            {
              id: 3,
              value: ".",
            },
          ];
          obj["selectedDateFormat"] = "DDMMYYYY";
          obj["selectedDateFormatSeperator"] = "/";
        }

        this.pageDesign.blocksArray.push(obj);
      }

      this.$refs.addNewInputCompRef.close();
    },

    addLeadInputData(input) {
      if (input) {
        const obj = {
          type: "freeText",
          labelText: "Label",
          showLabel: false,
          isRequired: false,
          addInfoTypeEdit: false,
          newInfoType: "",
          dateFormat: [
            {
              id: 1,
              value: "DDMMYYYY",
            },
            {
              id: 2,
              value: "MMDDYYYY",
            },
            {
              id: 3,
              value: "YYYYMMDD",
            },
            {
              id: 4,
              value: "DDMM",
            },
            {
              id: 5,
              value: "MMDD",
            },
          ],
          dateFormatSeperator: [
            {
              id: 1,
              value: "/",
            },
            {
              id: 2,
              value: "-",
            },
            {
              id: 3,
              value: ".",
            },
          ],
          selectedDateFormat: "DDMMYYYY",
          selectedDateFormatSeperator: "/",
          infoType: [
            {
              text: "Full Name",
              type: "text",
              fieldName: "full_name",
              fieldValue: "",
            },
            {
              text: "Email",
              type: "email",
              fieldName: "email",
              fieldValue: "",
            },
            {
              text: "Phone Number",
              type: "number",
              fieldName: "phone_number",
              fieldValue: "",
            },

            {
              text: "Date",
              type: "number",
              fieldName: "date",
              dateText: "Date",
              monthText: "Month",
              yearText: "Year",
              fieldValue: "",
              dateValue: "",
              monthValue: "",
              yearValue: "",
            },
            {
              text: "Website",
              type: "text",
              fieldName: "website",
              fieldValue: "",
            },
            {
              text: "Organization",
              type: "text",
              fieldName: "organisation",
              fieldValue: "",
            },
          ],

          style: {
            marginTop: "10",
            marginBottom: "10",
            marginLeft: "10",
            marginRight: "10",
            borderRadius: "0",
            textOpacity: 100,
            color: "#000000",
            fontSize: 14,
            fontFamily: "Poppins",
            strokeOpacity: 100,
            strokeColor: "#000000",
            strokeRotation: 0,
            borderSize: "1",
            borderType: "border",
            DateTextColor: "#000000",
            DateFontFamily: "Poppins",
            DateFontSize: 14,
            DateTextOpacity: 100,
            DateTextDecoration: "unset",
            DateFontWeight: "bold",
            DateFontStyle: "normal",
            DateTextAlign: "left",
            textInputOpacity: "100",
            textInputAlign: "left",
            mobileMarginLeft: "10",
            mobileMarginRight: "10",
            mobileMarginTop: "10",
            mobileMarginBottom: "10",
          },
          customizeStatus: false,
          hoverStatus: false,
        };
        // getAllUsedLeadInputs
        if (input == "full_name") {
          (obj.InfoTypePlaceholder = "Full Name"),
            (obj.selectedInfoType = {
              text: "Full Name",
              type: "text",
              fieldName: "full_name",
              fieldValue: "",
            });
        }
        if (input == "email") {
          (obj.InfoTypePlaceholder = "Email"),
            (obj.selectedInfoType = {
              text: "Email",
              type: "email",
              fieldName: "email",
              fieldValue: "",
              enableOTP: false,
            });
        }
        if (input == "number") {
          (obj.InfoTypePlaceholder = "Phone Number"),
            (obj.selectedInfoType = {
              text: "Phone Number",
              type: "number",
              fieldName: "phone_number",
              fieldValue: "",
            });
        }
        if (input == "date") {
          (obj.InfoTypePlaceholder = "Date"),
            (obj.selectedInfoType = {
              text: "Date",
              type: "number",
              fieldName: "date",
              dateText: "Date",
              monthText: "Month",
              yearText: "Year",
              fieldValue: "",
              dateValue: "",
              monthValue: "",
              yearValue: "",
            });
        }
        if (input == "website") {
          (obj.InfoTypePlaceholder = "Website"),
            (obj.selectedInfoType = {
              text: "Website",
              type: "text",
              fieldName: "website",
              fieldValue: "",
            });
        }
        if (input == "organisation") {
          (obj.InfoTypePlaceholder = "Organisation"),
            (obj.selectedInfoType = {
              text: "organisation",
              type: "text",
              fieldName: "organisation",
              fieldValue: "",
            });
        }

        this.pageDesign.blocksArray.push(obj);
        this.$refs.addNewInputCompRef.close();
      }
    },

    createAddressInput() {
      const obj = {
        type: "address",
        isAddressLine1Required: false,
        isAddressLine2Required: false,
        isCityRequired: false,
        isStateRequired: false,
        isZipCodeRequired: false,
        isCountryRequired: false,
        showAddressLine1: false,
        showAddressLine2: false,
        showCity: false,
        showState: false,
        showZipCode: false,
        showCountry: false,
        showAddressLine1Label: true,
        showAddressLine2Label: true,
        showCityLabel: true,
        showStateLabel: true,
        showZipCodeLabel: true,
        showCountryLabel: true,
        AddressLine1Label: "Address",
        AddressLine2Label: "Address Line 2",
        CityLabel: "City/Town",
        StateLabel: "State/Region/Province",
        ZipCodeLabel: "Zip/Post Code",
        CountryLabel: "Country",
        AddressLine1Placeholder: "Address",
        AddressLine2Placeholder: "Address Line 2",
        CityPlaceholder: "City/Town",
        StatePlaceholder: "State/Region/Province",
        ZipCodePlaceholder: "Zip/Post Code",
        CountryPlaceholder: "Country",
        AddressLine1Input: "",
        AddressLine2Input: "",
        CityInput: "",
        StateInput: "",
        ZipCodeInput: "",
        CountryInput: "",

        style: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 5,
          marginRight: 5,
          mobileMarginBottom: 0,
          mobileMarginTop: 0,
          mobileMarginLeft: 0,
          mobileMarginRight: 0,
          borderRadius: 4,
          textOpacity: 100,
          color: "#000000",
          textDecoration: "unset",
          fontWeight: "bold",
          fontStyle: "normal",
          textAlign: "left",
          fontSize: 14,
          fontFamily: "Poppins",
          strokeOpacity: 100,
          strokeColor: "#000000",
          strokeRotation: 0,
          borderSize: 1.5,
          borderType: "border",
          DateTextColor: "#000000",
          DateFontFamily: "Poppins",
          DateFontSize: 14,
          DateTextOpacity: 100,
          DateTextDecoration: "unset",
          DateFontWeight: "bold",
          DateFontStyle: "normal",
          textInputAlign: "left",
          DateTextAlign: "left",
          textInputOpacity: 100,
        },
        customizeStatus: false,
        hoverStatus: false,
      };

      if (this.selectedAddressInputs.AddressLine1Input) {
        obj["showAddressLine1"] = true;
      }
      if (this.selectedAddressInputs.AddressLine2Input) {
        obj["showAddressLine2"] = true;
      }
      if (this.selectedAddressInputs.CityInput) {
        obj["showCity"] = true;
      }
      if (this.selectedAddressInputs.StateInput) {
        obj["showState"] = true;
      }
      if (this.selectedAddressInputs.ZipCodeInput) {
        obj["showZipCode"] = true;
      }
      if (this.selectedAddressInputs.CountryInput) {
        obj["showCountry"] = true;
      }

      this.pageDesign.blocksArray.push(obj);

      this.selectedAddressInputs = {
        AddressLine1Input: false,
        AddressLine2Input: false,
        CityInput: false,
        StateInput: false,
        ZipCodeInput: false,
        CountryInput: false,
      };

      this.$refs.addNewAddressCompRef.close();
    },
    checkExistingAddresField(input) {
      if (this.AllAddressBlocks) {
        let allInputs = [...this.AllAddressBlocks];
        if (allInputs && allInputs.length) {
          return allInputs.includes(input);
        }
      }

      return false;
    },

    // Custom Methods
    updatePageName(CurrPage) {
      let { name, pageDesign, value, id } = CurrPage;
      let titleText = this.getTitleText(pageDesign.blocksArray, id);
      let allowedPage = ["multipleAnswer", "singleAnswer", "stripePage"];
      let isQuestionPage = allowedPage.includes(value);

      if (titleText !== "" && titleText !== name && isQuestionPage) {
        const id = this.CurrentSelectedPage.id;
        const value = this.CurrentSelectedPage.value;
        const pageName = titleText;
        this.$store.commit("setUpdatePageName", {
          pageName,
          id,
          value,
        });
      }
    },

    getTitleText(arr, pageID) {
      let titleIndex = arr.findIndex((obj) => obj.type == "text");

      let QuesIndex = this.getAllQuestions.findIndex((obj) => obj.id == pageID);

      if (titleIndex > -1 && QuesIndex > -1) {
        return arr[titleIndex].content !== ""
          ? `Q${QuesIndex + 1}. ${arr[titleIndex].content}`
          : "";
      } else {
        return "";
      }
    },

    handleDuplicateElement(index) {
      const targetArray =
        this.CurrentSelectedPage.value === "resultPage" &&
        this.getResultPageType === "noResultPage"
          ? this.pageDesign.NoResultBlocksArray
          : this.pageDesign.blocksArray;

      const itemToDuplicate = cloneDeep(targetArray[index]);
      itemToDuplicate.customizeStatus = false;
      itemToDuplicate.hoverStatus = false;

      targetArray.splice(index, 0, itemToDuplicate);
    },
    handleShiftElement(obj) {
      let { direction, index } = obj;
      let targetArr =
        this.CurrentSelectedPage.value === "resultPage" &&
        this.getResultPageType === "noResultPage"
          ? this.pageDesign.NoResultBlocksArray
          : this.pageDesign.blocksArray;

      if (direction === "up" && index > 0) {
        // Swapping the element with the one above it
        let temp = targetArr[index - 1];
        this.$set(targetArr, index - 1, targetArr[index]);
        this.$set(targetArr, index, temp);
      } else if (direction === "down" && index < targetArr.length - 1) {
        // Swapping the element with the one below it
        let temp = targetArr[index + 1];
        this.$set(targetArr, index + 1, targetArr[index]);
        this.$set(targetArr, index, temp);
      }
    },

    handleAddElementDropParent(dropItemObj) {
      let allowedPlan = ["Pro", "Plus", "Enterprise plan Special"];
      let isUpperPlan = allowedPlan.includes(this.UserPlanData.name);
      let { index, item } = dropItemObj;
      if (index > -1) {
        let obj;
        if (item == "AddText") {
          obj = createTextElement();
        }
        if (item == "AddHtml") {
          obj = Object.assign({}, InitialPageValues.htmlElementDesign);
        }
        if (item == "AddButton") {
          obj = createBasicButtonElement();
        }
        if (item == "AddTimer") {
          obj = Object.assign({}, InitialPageValues.timerElementDesign);
        }
        if (item == "AddInlineImage") {
          obj = Object.assign({}, InitialPageValues.ImageCompDesign);
        }
        if (item == "AddTooltip") {
          obj = Object.assign({}, InitialPageValues.toolTipDesign);
        }
        if (item == "AddSkipBtn") {
          obj = Object.assign({}, InitialPageValues.skipBtnDesign);
        }
        if (item == "AddCheckbox") {
          obj = createCheckboxElement();
        }
        if (item == "AddSocialMedia") {
          obj = Object.assign({}, InitialPageValues.socialMediaDesign);
        }
        if (item == "AddCouponComp") {
          obj = Object.assign({}, InitialPageValues.couponElementDesign);
        }
        if (item == "AddShareLinks") {
          obj = Object.assign({}, InitialPageValues.shareResultLinkDesign);
        }
        if (item == "AddOTP") {
          if (isUpperPlan) {
            let emailIndex = this.infoTypeEmailIndex;

            if (emailIndex && emailIndex > -1) {
              obj = InitialPageValues.twoFactAuthInput;
            } else {
              this.$toasted.show("Email Input is required!", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              });
              return;
            }
          } else {
            this.$emit("triggerUpgradeModal", {
              title: "Upgrade for Enhanced Security with OTP",
              subText:
                "Enhance the authenticity of your quiz interactions with our One-Time Password (OTP) feature. This upgrade ensures that the email addresses collected through your quizzes are verified and authentic.",
            });
            return;
          }
        }
        if (item == "AddFileUpload") {
          if (isUpperPlan) {
            obj = Object.assign({}, InitialPageValues.fileUploadDesign);
          } else {
            this.$emit("triggerUpgradeModal", {
              title: "Upgrade to Enable File Uploads",
              subText:
                "Expand the capabilities of your quizzes by upgrading to include File Uploads. This feature allows quiz participants to easily upload files, enriching their responses and interaction with your content. ",
            });
            return;
          }
        }

        if (
          this.CurrentSelectedPage.value == "resultPage" &&
          this.getResultPageType == "noResultPage"
        ) {
          this.pageDesign.NoResultBlocksArray.splice(index, 0, obj);
        } else {
          this.pageDesign.blocksArray.splice(index, 0, obj);
        }
      }
    },
  },
  computed: {
    checkBauerfeind() {
      return process.env?.VUE_APP_BAUERFEIND_QUIZID.includes(
        this.CurrentSelectedPage.quiz_id
      );
    },
    isBearWithBenefitsClient() {
      const quizId = this.CurrentSelectedPage?.quiz_id;
      const bearWithBenefitsIds = process.env.VUE_APP_BEARWBENEFITS_QUIZID;

      if (!quizId) return false;

      return bearWithBenefitsIds.includes(quizId) || quizId == 11799;
    },

    AddButtonClass() {
      if (this.CurrentSelectedPage.value == "resultPage") {
        return null;
      } else {
        return this.maxButtonLimit > 0 ? "div-disable" : "cursorPointer";
      }
    },
    getQuestionPageType: {
      get() {
        return this.QuestionPageType == "multipleAnswer" ? true : false;
      },
      set(newValue) {
        if (newValue) {
          this.QuestionPageType = "multipleAnswer";
        } else {
          this.QuestionPageType = "singleAnswer";
        }
      },
    },
    getPageDesignBlocks() {
      if (
        this.CurrentSelectedPage.value == "resultPage" &&
        this.getResultPageType == "noResultPage"
      ) {
        return this.pageDesign.NoResultBlocksArray;
      } else {
        return this.pageDesign.blocksArray;
      }
    },

    getPageDesignBlocksLength() {
      return this.getPageDesignBlocks ? this.getPageDesignBlocks.length : 0;
    },

    ...mapGetters([
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getEditorPageView",
      "getPlanData",
      "getPageZoom",
      "getCustomNavFlexGrowValue",
      "getAllQuestions",
      "getLoadQuizAppsIntegrated",
      "getIsEditQuizSaveLoading"
    ]),
    isEditorQuizSaveLoading(){
      return this.getIsEditQuizSaveLoading
    },
    UserPlanData() {
      return this.getPlanData;
    },
    maxButtonLimit() {
      let count = 0;

      if (
        this.CurrentSelectedPage.value == "resultPage" &&
        this.getResultPageType == "noResultPage"
      ) {
        this.pageDesign.NoResultBlocksArray.forEach(
          (x) => x.type == "button" && count++
        );
      } else {
        this.pageDesign.blocksArray.forEach(
          (x) => x.type == "button" && count++
        );
      }

      return count;
    },
    maxOptionLimit() {
      let count = 0;
      this.pageDesign.blocksArray.forEach((x) => x.type == "option" && count++);
      return count;
    },
    getOptionIndex() {
      let index = this.pageDesign.blocksArray.findIndex(
        (block) => block.type == "option"
      );
      if (index > -1) {
        return index;
      } else {
        return null;
      }
    },

    maxHtmlElementLimit() {
      let count = 0;
      this.pageDesign.blocksArray.forEach((x) => x.type == "html" && count++);
      return count;
    },
    minTextCount() {
      let count = 0;
      this.pageDesign.blocksArray.forEach((x) => x.type == "text" && count++);

      return count;
    },

    // Lead Page computed Functions start

    getDateIndex() {
      let index = this.pageDesign.blocksArray.findIndex(
        (e) => e.type == "freeText" && e.selectedInfoType.text == "Date"
      );
      return index;
    },

    infoTypeEmailIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) =>
          block.type == "freeText" &&
          block.selectedInfoType.fieldName == "email"
      );
      if (index > -1) {
        return index;
      }
      return null;
    },
    infoTypeEmailExist() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) =>
          block.type == "freeText" &&
          block.selectedInfoType.fieldName == "email"
      );
      if (index > -1) {
        return true;
      }
      return false;
    },
    getFreeTextAreaInputIndexArr() {
      let index = this.pageDesign.blocksArray
        .map((block, index) => (block.type == "freeTextArea" ? index : null))
        .filter((index) => index !== null);
      return index;
    },

    getAllUsedLeadInputs() {
      if (this.getAllLeadPagesArr.length) {
        let InputElementArr = [];

        this.getAllLeadPagesArr.forEach((item) => {
          item.pageDesign.blocksArray.forEach((block) => {
            if (block.type == "freeText") {
              if (block.selectedInfoType.text == "Full Name") {
                InputElementArr.push("full_name");
              }
              if (block.selectedInfoType.text == "Email") {
                InputElementArr.push("email");
              }
              if (block.selectedInfoType.text == "Phone Number") {
                InputElementArr.push("number");
              }
              if (block.selectedInfoType.text == "Website") {
                InputElementArr.push("website");
              }
              if (block.selectedInfoType.text == "organisation") {
                InputElementArr.push("organisation");
              }
              if (block.selectedInfoType.text == "Date") {
                InputElementArr.push("date");
              }
            }
            if (block.type == "customInput") {
              InputElementArr.push(block.selectedInfoType.fieldName);
            }
            if (block.type == "checkbox") {
              InputElementArr.push("checkbox");
            }
            if (block.type == "freeTextArea") {
              InputElementArr.push("freeTextArea");
            }
            if (block.type == "address") {
              InputElementArr.push("address");
            }
          });
        });

        return InputElementArr;
      } else {
        return null;
      }
    },

    maxFileUpload() {
      let count = 0;
      this.pageDesign.blocksArray.forEach(
        (x) => x.type == "fileUpload" && count++
      );
      return count;
    },
    maxCheckBoxLimit() {
      let count = 0;
      this.pageDesign.blocksArray.forEach(
        (x) => x.type == "checkbox" && count++
      );
      return count;
    },

    maxFreeTextAreaInputLimit() {
      let count = 0;
      this.pageDesign.blocksArray.forEach(
        (x) => x.type == "freeTextArea" && count++
      );
      return count;
    },
    AllAddressBlocks() {
      if (this.getAllLeadPagesArr.length > 0) {
        let LeadsData = [...this.getAllLeadPagesArr];
        let addedAddressFields = [];

        const allBlocks = LeadsData.flatMap(
          (item) => item.pageDesign.blocksArray
        );
        const addressBlocks = allBlocks.filter(
          (block) => block.type === "address"
        );

        // return addressBlocks;

        addressBlocks.forEach((block) => {
          if (block.showAddressLine1) {
            addedAddressFields.push(`AddressLine1`);
          }
          if (block.showAddressLine2) {
            addedAddressFields.push(`AddressLine2`);
          }
          if (block.showCity) {
            addedAddressFields.push(`City`);
          }
          if (block.showCountry) {
            addedAddressFields.push(`Country`);
          }
          if (block.showState) {
            addedAddressFields.push(`State`);
          }
          if (block.showZipCode) {
            addedAddressFields.push(`zipCode`);
          }
        });

        return [...new Set(addedAddressFields)];
      } else {
        return null;
      }
    },
    getAllLeadPagesArr() {
      let LeadPages = this.getTotalPages.filter(
        (item) => item.value == "leadPage"
      );

      return LeadPages;
    },

    getOTPAuthIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "Auth"
      );
      return index;
    },
    isAuthOTPExist() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "Auth"
      );

      return index > -1 ? true : false;
    },
    isBothEmailAndOTPpresent() {
      return this.infoTypeEmailExist && this.isAuthOTPExist;
    },

    // Lead Page computed Functions end

    // Stripe Page start
    getStripePriceObjIndex() {
      return this.pageDesign.blocksArray.findIndex((e) => e.type == "price");
    },
    getPricePaymentButtonIndex() {
      return this.pageDesign.blocksArray.findIndex(
        (e) => e.type == "paymentButton"
      );
    },
    getPriceCompIndex() {
      return this.pageDesign.blocksArray.findIndex((e) => e.type == "price");
    },
    // Stripe Page end

    // Result Page Computed
    getResultPageType() {
      return this.resultPageType;
    },

    maxSocialLinks() {
      let count = 0;
      if (this.pageDesign.pageType == "resultPage") {
        this.pageDesign.blocksArray.forEach(
          (x) => x.type == "socialLinks" && count++
        );
      } else {
        this.pageDesign.NoResultBlocksArray.forEach(
          (x) => x.type == "socialLinks" && count++
        );
      }
      return count;
    },
    maxShareLinks() {
      let count = 0;
      if (this.pageDesign.pageType == "resultPage") {
        this.pageDesign.blocksArray.forEach(
          (x) => x.type == "shareLink" && count++
        );
      } else {
        this.pageDesign.NoResultBlocksArray.forEach(
          (x) => x.type == "shareLink" && count++
        );
      }
      return count;
    },
    maxCouponComp() {
      let count = 0;
      if (this.pageDesign.pageType == "resultPage") {
        this.pageDesign.blocksArray.forEach(
          (x) => x.type == "coupon" && count++
        );
      }
      return count;
    },
    getProductRankOptionArrayIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) => block.type == "product"
      );

      if (index > -1) {
        return index;
      } else {
        return null;
      }
    },
    getSocialLinksIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) => block.type == "socialLinks"
      );

      if (index > -1) {
        return index;
      } else {
        return null;
      }
    },
    createRankOptionArray() {
      const rankOptionArray = [
        {
          text: "1st",
        },
        {
          text: "2nd",
        },
        {
          text: "3rd",
        },
      ];

      return rankOptionArray;
    },
    // Result Page end

    // Image Related functions
    IsImageAdded() {
      if (this.CurrentSelectedPage.value == "resultPage") {
        if (this.pageDesign.pageType == "resultPage") {
          return this.pageDesign.imageBlock.src !== "" ? true : false;
        } else {
          return this.pageDesign.imageBlock.noResultSrc !== "" ? true : false;
        }
      } else {
        return this.pageDesign.imageBlock.src !== "" ? true : false;
      }
    },
  },
  created() {
    this.getPageDesignValues();
  },

  mounted() {
    // Listen to Events from children Comp
    centralEventBus.$on("addHover", this.handleAddHover);
    centralEventBus.$on("removeHover", this.handleRemoveHover);
    centralEventBus.$on("changeCustomize", this.handleChangeCustomize);
    centralEventBus.$on("activeImageCustomize", this.handleImageCustomize);
    centralEventBus.$on("deleteImage", this.handleDeleteImage);
    centralEventBus.$on("deleteBlock", this.handleDeleteBlock);
    centralEventBus.$on("addStaticHover", this.handleAddStaticHover);
    centralEventBus.$on("removeStaticHover", this.handleRemoveStaticHover);
    centralEventBus.$on(
      "changeStaticCustomisation",
      this.handleStaticCustomize
    );
    centralEventBus.$on("disableOtp", this.handleDisableOtp);
    centralEventBus.$on(
      "handleBlocksArrayDraggable",
      this.handleBlocksArrayDraggable
    );
    centralEventBus.$on(
      "handleNoResultBlocksArrayDraggable",
      this.handleNoResultBlocksArrayDraggable
    );
    centralEventBus.$on("handleDuplicate", this.handleDuplicateElement);
    centralEventBus.$on("shiftEditorElement", this.handleShiftElement);
    centralEventBus.$on(
      "handleAddElementDrop",
      this.handleAddElementDropParent
    );

    if (
      Object.prototype.hasOwnProperty.call(
        this.CurrentSelectedPage,
        "pageDesign"
      )
    ) {
      this.updatePageName(this.CurrentSelectedPage);
      this.ReArrangeQuizElement();
    }
    // Fetch Custom Input Data
    this.fetchCustomInputFields();
  },
  beforeDestroy() {
    // Clean up the event listener to prevent memory leaks
    centralEventBus.$off("addHover", this.handleAddHover);
    centralEventBus.$off("removeHover", this.handleRemoveHover);
    centralEventBus.$off("changeCustomize", this.handleChangeCustomize);
    centralEventBus.$off("activeImageCustomize", this.handleImageCustomize);
    centralEventBus.$off("deleteImage", this.handleDeleteImage);
    centralEventBus.$off("deleteBlock", this.handleDeleteBlock);
    centralEventBus.$off("removeStaticHover", this.handleRemoveStaticHover);
    centralEventBus.$off(
      "changeStaticCustomisation",
      this.handleStaticCustomize
    );
    centralEventBus.$off("disableOtp", this.handleDisableOtp);
    centralEventBus.$off(
      "handleBlocksArrayDraggable",
      this.handleBlocksArrayDraggable
    );
    centralEventBus.$off(
      "handleNoResultBlocksArrayDraggable",
      this.handleNoResultBlocksArrayDraggable
    );
    centralEventBus.$off("shiftEditorElement", this.handleShiftElement);
    centralEventBus.$off("handleDuplicate", this.handleDuplicateElement);
    centralEventBus.$off(
      "handleAddElementDrop",
      this.handleAddElementDropParent
    );
  },

  watch: {
    shuffleOptions(newVal) {
      this.$set(
        this.CurrentSelectedPage.pageDesign.blocksArray[this.getOptionIndex],
        "shuffleOptions",
        newVal
      );
    },
    async CurrentSelectedPage() {
      await this.getPageDesignValues();
      this.updatePageName(this.CurrentSelectedPage);
      this.ReArrangeQuizElement();
    },
    resultPageType(newVal, oldVal) {
      if (newVal && oldVal) {
        this.pageDesign.pageType = newVal;
      }
    },
    pageDesign: {
      deep: true,
      handler: function () {
        if(this.isEditorQuizSaveLoading) return
        const id = this.CurrentSelectedPage.id;
        const value = this.CurrentSelectedPage.value;
        const pageDesignValue = this.pageDesign;
        this.$store.commit("setUpdatePageValues", {
          pageDesignValue,
          id,
          value,
        });
      },
    },
    getPageDesignBlocksLength(newVal, oldVal) {
      if (newVal != oldVal) {
        this.ReArrangeQuizElement();
      }
    },

    // Single /Muliple Ans Page Watch Start
    IsMasterQuestion() {
      const order = this.getSelectedPage.order;
      const value = this.getSelectedPage.value;
      const isMasterQuestion = this.IsMasterQuestion;
      this.$store.commit("setUpdatePageisMasterQuestion", {
        isMasterQuestion,
        order,
        value,
      });
    },
    skip_status() {
      const order = this.getSelectedPage.order;
      const value = this.getSelectedPage.value;
      const skip_status = this.skip_status;
      this.$store.commit("setUpdatePageSkipStatus", {
        skip_status,
        order,
        value,
      });
    },
    QuestionPageType() {
      const order = this.getSelectedPage.order;
      const name = this.getSelectedPage.name;
      let questionPageType, icon;
      if (this.QuestionPageType == "singleAnswer") {
        icon = "/images/singleAnswer.svg";
      } else {
        icon = "/images/multipleAnswer.svg";
      }
      questionPageType = this.QuestionPageType;
      this.$store.commit("setUpdatePageQuestionPageType", {
        name,
        order,
        questionPageType,
        icon,
      });
    },

    // Single /Muliple Ans Page Watch End
  },
};
</script>

<style scoped>
.pageElement {
  height: 90px;
  color: #73738d;
  border-radius: 8px;
  border: 1px solid #18191c14 !important;
}

.pageElement:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.pageElement svg {
  margin-bottom: 5px;
}
.pageElement p {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
}
.result-select {
  padding-left: 35px;
  background: #f4f3f5;
  height: inherit;
  border: none !important;
}
.custom-select-aanswer {
  display: flex;
  border: 1px solid #000000;
  border-radius: 6px;
  height: 36px;
  /* background:#ededee; */
  align-items: center;
}
.custom-select-aanswer img {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 24px;
}

.inputBxShadow {
  box-shadow: 0px 4px 10px rgba(10, 4, 17, 0.15);
}
.inputBxShadow:hover {
  box-shadow: 0px 4px 10px rgba(10, 4, 17, 0.25);
}

.addressCustomInput {
  cursor: pointer;
}
.addressCustomInputDisabled {
  opacity: 0.4 !important;
}

.settingsInput {
  padding: 2px;
  width: 80%;
  border-radius: 6px;
  border: 0.8px solid #d2d8e0;
}

.settingsInput input {
  padding-left: 2px;
  border: 0;
  outline: 0;
  color: #73738d;
}
.settingsInput input:focus {
  border: 0;
  outline: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
.custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff !important;
}
</style>
